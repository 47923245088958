import { endOfDay, format, parse } from "date-fns";

/** Parses useful information out of option symbol strings */
export class OptionSymbolParser {
  constructor(private readonly symbol: string) {}

  strikePrice(): number {
    return +this.symbol.split("_")[1].split(/[CP]/)[1];
  }

  expiration(): Date {
    const expirationStr = this.symbol.split("_")[1].substring(0, 6);
    return endOfDay(parse(expirationStr, "MMddyy", new Date()));
  }

  underlying(): string {
    return this.symbol.split("_")[0];
  }

  // Returns C for CALL or P for PUT
  shortType(): "P" | "C" {
    const shorthandType = this.symbol.split("_")[1].match(/[CP]/);
    if (!shorthandType) {
      throw new Error(
        `Unable to parse the option type from the symbol ${this.symbol}`,
      );
    }
    return shorthandType[0] as "P" | "C";
  }

  type(): "CALL" | "PUT" {
    return this.shortType() === "P" ? "PUT" : "CALL";
  }

  toHuman(): string {
    const expiration = format(this.expiration(), "MM/dd/yy");
    const underlying = this.underlying();
    const strikePrice = this.strikePrice();
    const type = this.type();
    return `${underlying} ${expiration} ${type} ${strikePrice}`;
  }

  // Returns this option symbol in the format suitable for use with the terminal
  toTerminalInstrument(): string {
    const expiration = format(this.expiration(), "yyMMdd");
    const underlying = this.underlying();
    const strikePrice = this.strikePrice();
    const shortType = this.shortType();
    return `.${underlying}${expiration}${shortType}${strikePrice}`;
  }
}
