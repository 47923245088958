import { addDays, isWeekend, parse } from "date-fns";
import { StockPrice } from "../models/Types";
import { mean, sum } from "lodash";

export function parseChartData(d: any): StockPrice {
  return {
    date: parse(d.date, "T", new Date()),
    open: +d.open,
    high: +d.high,
    low: +d.low,
    close: +d.close,
    volume: +d.volume,
  };
}

export function isDarkMode(): boolean {
  return (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  );
}

/** Returns today if it's a weekday or last Friday if it's a weekend */
export function todayOrLastWeekday(): Date {
  let currDate = new Date();
  while (isWeekend(currDate)) {
    currDate = addDays(currDate, -1);
  }
  return currDate;
}

// Analogous to `Security#company_name_to_logo_filename`
export function sanitizeCompanyName(company: string): string {
  let c = company;
  const suffixes = [
    /\sinc\.$/i,
    /\ss\.a\./i,
    /\scorporation$/i,
    /\scorp$/i,
    /\sltd$/i,
    /\sholdings/i,
    /\snv$/i,
    /\scompany$/i,
    /\corporation$/i,
    /^the\s/i,
    /,$/i,
    " &",
    "'",
  ];
  suffixes.forEach((s) => (c = c.replace(s, "")));
  return c;
}

// https://stackoverflow.com/questions/822452/strip-html-from-text-javascript/47140708#47140708
export function stripHtmlEntities(html: string): string {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

export function ensure<T>(value: T, errorMsg: string): NonNullable<T> | never {
  return value ?? error(errorMsg);
}

export function throwError(msg: string): never {
  throw new Error(msg);
}

export function sampleVariance(values: number[]): number {
  const avg = mean(values);
  return sum(values.map((p) => (p - avg) ** 2)) / (values.length - 1);
}

export function populationVariance(values: number[]): number {
  const avg = mean(values);
  return sum(values.map((p) => (p - avg) ** 2)) / values.length;
}

export function returns(values: number[]): number[] {
  // need at least 2 values in the sample to calculate returns
  if (values.length < 2) {
    return [];
  } else {
    const returns = values.map((v, i) => (i === 0 ? 0 : v / values[i - 1] - 1));
    // drop the first element (always zero)
    return returns.slice(1);
  }
}

// Returns the sample standard deviation (square root of variance)
export function sampleStandardDeviation(values: number[]): number {
  return Math.sqrt(sampleVariance(values));
}

const error = (msg: string) => {
  throw new Error(msg);
};

/**
 * Returns a human-readable string indicating whether earnings happen before market open or after
 * market close
 */
export function earningsTimeToHuman(
  earningsDate: Date,
  capitalize: boolean = true,
): string {
  const hour = earningsDate.getUTCHours();
  const msg = hour <= 14 ? "Before market open" : "After market close";
  return capitalize ? msg : msg.toLowerCase();
}

/** Returns the CSS class for displaying a positive/negative number with a color indicator */
export function numberToCssClass(num: number): string {
  return num > 0 ? "text-amount text-success" : "text-amount text-danger";
}
