import React, { ReactNode } from "react";
import CurrencyAmount from "./currencyAmount";

export type DashboardCardProps = {
  className?: string;
  title: string;
  amount: number;
  children: ReactNode | ReactNode[];
  viewMoreLink?: string;
};

const CardComponent = ({
  className,
  title,
  amount,
  children,
  viewMoreLink,
}: DashboardCardProps) => (
  <div
    className={`rounded-lg shadow dark:bg-gray-700 bg-white mb-3 sm:w-auto ${className}`}
  >
    <div className="flex items-center px-4 py-8">
      <div className="text-4xl mr-6">{children}</div>
      <div>
        <span className="font-medium dark:text-gray-300 text-gray-500">
          {title}
        </span>
        <div className="font-semibold mt-3">
          <CurrencyAmount amount={amount} extraClass="text-lg" />
        </div>
      </div>
    </div>
    {viewMoreLink && (
      <div className="dark:bg-gray-600 bg-gray-100 px-4 py-3 rounded-b-lg -mt-4">
        <a
          href={viewMoreLink}
          className="text-sm font-semibold cursor-pointer dark:text-indigo-300 text-indigo-700"
        >
          View more
        </a>
      </div>
    )}
  </div>
);
export default CardComponent;
