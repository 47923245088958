import { format, startOfMonth } from "date-fns";
import { isEmpty, sortBy, sum, sumBy } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  accountPositionToEquity,
  accountPositionToOption,
  equityUnrealizedNetProfitOrLoss,
  filterEquities,
  filterOptions,
  optionUnrealizedNetProfitOrLoss,
} from "./util/accountPosition";
import HusklyAccountsApiClient, {
  AccountDetails,
} from "./api/husklyAccountsApiClient";
import BookOpenOutlineIcon from "./icons/bookOpenOutlineIcon";
import CircularProgress from "./circularProgress";
import CardComponent from "./cardComponent";
import EquitiesTable from "./equitiesTable";
import { Trade } from "./models/Trade";
import OptionsTable from "./optionsTable";
import TrendingUpOutlineIcon from "./tradingUpOutlineIcon";
import { PositionType } from "./models/positionType";
import selectAccountById from "./util/accountSelector";
import CurrencyDollarIcon from "@heroicons/react/24/outline/CurrencyDollarIcon";

interface DashboardComponentProps {
  selectedAccountId?: string;
}

const DashboardComponent = ({
  selectedAccountId: _selectedAccountId,
}: DashboardComponentProps) => {
  // fallback to account ID saved in local storage if one is not explicitly
  // provided in the params. This allows us to memorize when a user switches
  // accounts, it will always load that one by default in the future when they
  // visit `/dashboard`
  const selectedAccountId =
    _selectedAccountId || localStorage.getItem("selectedAccountId");
  const accountsApiClient = new HusklyAccountsApiClient();
  const [isLoading, setIsLoading] = useState(true);
  const [failedLoading, setFailedLoading] = useState(false);
  // Array of all available user accounts (irrespective of which one is selected)
  const [accounts, setAccounts] = useState<AccountDetails[]>([]);
  const [trades, setTrades] = useState<Trade[]>([]);

  useEffect(() => {
    const accountsFetcher = async () => {
      const allAccounts = await accountsApiClient.requestAccounts();
      const now = new Date();
      const startDate = startOfMonth(now);
      const trades = await accountsApiClient.requestTrades({
        startDate,
        endDate: now,
        tdaAccountId: selectedAccountId,
      });
      if ("error" in trades) {
        throw new Error(trades.error);
      } else {
        return { allAccounts, trades };
      }
    };
    accountsFetcher()
      .then(async ({ allAccounts, trades }) => {
        if (Array.isArray(trades)) {
          const topLevelTrades = trades.filter(({ isInner }) => !isInner);
          setAccounts(allAccounts);
          setTrades(topLevelTrades);
          setIsLoading(false);
          // const positions = allAccounts.flatMap(({ positions }) => positions);
          // subscribe to price updates for all the symbols we care about
          // const symbols = positions.map(positionSymbol);
        } else {
          setFailedLoading(true);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setFailedLoading(true);
        setIsLoading(false);
      });
  }, []);

  // called every time a websocket price update message callback is fired
  /*const onNewLastPriceReceived = (
    accounts: AccountDetails[],
    message: ParsedWebSocketResponse
  ) => {
    const positions = accounts.flatMap(({ positions }) => positions);
    if (isQuotesResponse(message)) {
      message.quotes.forEach(({ symbol, last }) => {
        const position = positions.find((p) => positionSymbol(p) === symbol);
        if (position) {
          // we received and update for this symbol but last prices doesn't always change
          if (last) {
            position.previousPrice = +priceFormat(position.lastPrice);
            position.lastPrice = +priceFormat(last);
            // https://stackoverflow.com/questions/56266575/why-is-usestate-not-triggering-re-render
            setAccounts([...accounts]);
          }
        } else {
          console.error(
            `Received price update for symbol ${symbol} but no account position was found for it`
          );
        }
      });
    }
  };*/

  if (failedLoading) {
    return <FailedToLoad />;
  } else if (isLoading || !accounts) {
    return <LoadingIndicator />;
  }
  const selectedAccount = selectAccountById(accounts, selectedAccountId);
  const allAccountIds = accounts.map(({ accountId }) => accountId);
  const isSingleAccount = allAccountIds.length === 1;
  const positions = selectedAccount?.positions || [];
  const equities = sortBy(
    filterEquities(positions),
    (p) => p.instrument.symbol,
  );
  const options = sortBy(filterOptions(positions), (p) => p.instrument.symbol);
  const unrealizedOptionsPnL = sum(
    options.map(optionUnrealizedNetProfitOrLoss),
  );
  const unrealizedEquitiesPnL = sum(
    equities.map(equityUnrealizedNetProfitOrLoss),
  );
  const isLong = ({ type }: { type: PositionType }) =>
    type === PositionType.LONG;
  const isShort = ({ type }: { type: PositionType }) =>
    type === PositionType.SHORT;
  const equityPositions = equities.map(accountPositionToEquity);
  const optionPositions = options.map(accountPositionToOption);
  const longEquities = equityPositions.filter(isLong);
  const shortEquities = equityPositions.filter(isShort);
  const shortOptionPositions = optionPositions.filter(isShort);
  const longOptionPositions = optionPositions.filter(isLong);
  const monthNetPnL = sumBy(trades, (t: Trade) => t.netProfit);
  return (
    <div className="bg-slate-100 dark:bg-gray-800 min-h-screen pb-8">
      <div className="max-w-7xl mx-auto">
        <div className="p-8">
          <div className="flex justify-center">
            <div className="flex flex-col max-w-full">
              <div className="align-middle inliner-block max-w-full overflow-auto">
                <div className="flex justify-between mb-8 flex-wrap">
                  <h2 className="font-semibold text-2xl text-gray-900 dark:text-gray-300">
                    {format(new Date(), "MMMM")} P/L Summary
                  </h2>
                  <div className="flex text-sm items-center">
                    <div className="mr-2">
                      {isSingleAccount ? "Account" : "Available accounts:"}
                    </div>
                    <AccountSelector
                      allAccountIds={allAccountIds}
                      selectedAccountId={selectedAccount!.accountId}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap justify-between mb-8 gap-x-2">
                  <CardComponent
                    className="grow shrink"
                    title="Net income"
                    amount={monthNetPnL}
                    viewMoreLink="/trades"
                  >
                    <CurrencyDollarIcon className="h-12 w-12 dark:stroke-gray-300 stroke-gray-500" />
                  </CardComponent>
                  <CardComponent
                    className="grow shrink"
                    title="Unrealized equities"
                    amount={unrealizedEquitiesPnL}
                  >
                    <TrendingUpOutlineIcon />
                  </CardComponent>
                  <CardComponent
                    className="grow shrink"
                    title="Unrealized options"
                    amount={unrealizedOptionsPnL}
                  >
                    <BookOpenOutlineIcon />
                  </CardComponent>
                </div>
                {!isEmpty(longEquities) && (
                  <div className="border-b border-gray-200 dark:border-gray-700 rounded-lg mb-8">
                    <h3 className="font-semibold text-2xl text-slate-700 text-gray-900 dark:text-gray-300 mb-8">
                      <span className="px-3 py-1 inline-flex font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                        Long
                      </span>{" "}
                      Equity Positions
                    </h3>
                    <EquitiesTable equities={longEquities} />
                  </div>
                )}
                {!isEmpty(shortEquities) && (
                  <div className="border-b border-gray-200 dark:border-gray-700 rounded-lg mb-8">
                    <h3 className="font-semibold text-2xl text-slate-700 text-gray-900 dark:text-gray-300 mb-8">
                      <span className="px-3 py-1 inline-flex font-semibold rounded-full bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-400">
                        Short
                      </span>{" "}
                      Equity Positions
                    </h3>
                    <EquitiesTable equities={shortEquities} />
                  </div>
                )}
                {!isEmpty(longOptionPositions) && (
                  <div className="border-b border-gray-200 dark:border-gray-700 rounded-lg mb-8">
                    <h3 className="font-semibold text-2xl text-slate-700 text-gray-900 dark:text-gray-300 mb-8">
                      <span className="px-3 py-1 inline-flex font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                        Long
                      </span>{" "}
                      Option Positions
                    </h3>
                    <OptionsTable options={longOptionPositions} />
                  </div>
                )}
                {!isEmpty(shortOptionPositions) && (
                  <div className="border-b border-gray-200 dark:border-gray-700 rounded-lg mb-8">
                    <h3 className="font-semibold text-2xl text-slate-700 text-gray-900 dark:text-gray-300 mb-8">
                      <span className="px-3 py-1 inline-flex font-semibold rounded-full bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-400">
                        Short
                      </span>{" "}
                      Option Positions
                    </h3>
                    <OptionsTable options={shortOptionPositions} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingIndicator = () => (
  <div className="bg-slate-100 dark:bg-gray-800 min-h-screen">
    <div className="max-w-7xl mx-auto">
      <div className="p-8">
        <div className="text-center">
          <CircularProgress />
        </div>
      </div>
    </div>
  </div>
);

const AccountSelector = ({
  allAccountIds,
  selectedAccountId,
}: {
  allAccountIds: string[];
  selectedAccountId: string;
}) => {
  const [privacyModeEnabled, setPrivacyModeEnabled] = useState(
    localStorage.getItem("privacyModeEnabled") === "true",
  );
  useEffect(() => {
    window.addEventListener("storage", () => {
      console.log("event!");
      const enabled =
        window.localStorage.getItem("privacyModeEnabled") === "true";
      setPrivacyModeEnabled(enabled);
    });
  }, []);
  return (
    <>
      {allAccountIds.map((accountId) =>
        accountId === selectedAccountId ? (
          <div
            className="px-2 py-1 dark:text-gray-100 text-gray-100 bg-teal-800 rounded"
            key={accountId}
          >
            #{privacyModeEnabled ? "••••••••" : accountId}
          </div>
        ) : (
          <div className="px-3 py-1 dark:text-gray-100" key={accountId}>
            <a
              href={`/dashboard/${accountId}`}
              onClick={() =>
                localStorage.setItem("selectedAccountId", accountId)
              }
            >
              #{privacyModeEnabled ? "••••••••" : accountId}
            </a>
          </div>
        ),
      )}
    </>
  );
};

const FailedToLoad = () => (
  <div className="bg-slate-100 dark:bg-gray-800 min-h-screen">
    <div className="max-w-7xl mx-auto">
      <div className="p-8">
        <div className="text-center text-red-500">
          <p>Failed to load your account details. Please check back later!</p>
        </div>
      </div>
    </div>
  </div>
);
export default DashboardComponent;
