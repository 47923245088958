import React, { useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

const FlashAlert = ({
  message,
  visible = true,
  onClose = () => {},
}: {
  message: string;
  visible?: boolean;
  onClose?: () => void;
}) => {
  const [hidden, setHidden] = useState(!visible);
  const hideFlash = () => {
    setHidden(true);
    onClose();
  };
  return (
    <p
      className={`p-3 rounded-full bg-orange-300 dark:bg-orange-900 alert-danger text-center alert-dismissible flex drop-shadow ${
        hidden && "hidden"
      }`}
      role="alert"
    >
      <ExclamationTriangleIcon className="h-6 w-6 mr-3" />
      <span className="mr-2">{message}</span>
      <XCircleIcon className="h-6 w-6 cursor-pointer" onClick={hideFlash} />
    </p>
  );
};
export default FlashAlert;
