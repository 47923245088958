import { isEmpty } from "lodash";
import Quote from "../models/Quote";
import {
  FrequencyApiParam,
  FrequencyTypeApiParam,
  PeriodTypeApiParam,
  StockPrice,
} from "../models/Types";
import { parseChartData } from "../util/Util";

export type InstrumentSearchMatch = {
  cusip: string;
  symbol: string;
  description: string;
  exchange: string;
  assetType: string;
};
export type InstrumentSearchResult = {
  [key: string]: InstrumentSearchMatch;
};

export default class HusklyApiClient {
  /** Finds all instruments that match the provided symbol prefix `searchTerm` */
  async searchInstruments(searchTerm: string): Promise<InstrumentSearchResult> {
    const queryParams = "projection=symbol-regex";
    const baseUrl = `/api/v1/instrument/${searchTerm}.*`;
    const url = `${baseUrl}?${queryParams}`;
    const response = await fetch(url);
    return await response.json();
  }

  /** Returns price history for the provided symbol */
  async fetchPriceHistory({
    symbol,
    periodType = "day",
    frequency,
    frequencyType,
    startDateMillis,
    includeExtendedHours = true,
  }: {
    symbol: string;
    frequency: FrequencyApiParam;
    periodType?: PeriodTypeApiParam;
    frequencyType: FrequencyTypeApiParam;
    startDateMillis?: number;
    includeExtendedHours?: boolean;
  }): Promise<StockPrice[]> {
    const baseUrl = `/api/v1/prices/history/${encodeURIComponent(symbol)}`;
    const tz = encodeURIComponent(
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
    const startDate = startDateMillis
      ? `startDateMillis=${startDateMillis}`
      : "";
    const queryParams = [
      `tz=${tz}`,
      `includeExtendedHours=${includeExtendedHours}`,
      `freq=${frequency}`,
      `periodType=${periodType}`,
      `freqType=${frequencyType}`,
      startDate,
    ].join("&");
    const url = `${baseUrl}?${queryParams}`;
    const response = await fetch(url);
    const prices = await response.json();
    return prices.map(parseChartData);
  }

  /**
   * Uses the quotes API to return the last price (mark) for each of the provided symbols.
   * Example return data for symbols ["AAPL", "FB"]:
   *   { AAPL: 110.12, FB: 230.11 }
   */
  async fetchLastPrices(symbols: Set<string>): Promise<Record<string, number>> {
    const quotes = await this.fetchQuotes(symbols);
    return quotes.reduce((acc: Record<string, number>, quote: any) => {
      const mark = quote.bidPrice + (quote.askPrice - quote.bidPrice) / 2;
      return {
        ...acc,
        [quote.symbol]: mark,
      };
    }, {});
  }

  async fetchQuotes(symbols: Set<string>): Promise<Quote[]> {
    if (isEmpty(symbols)) {
      return [];
    } else {
      const response = await fetch(`/api/v1/quotes/${[...symbols].join(",")}`);
      return await response.json();
    }
  }
}
