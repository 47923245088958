import { parse } from "date-fns";
import { formatAsCurrency } from "../util/Format";
import { OptionSymbolParser } from "../util/optionSymbolParser";

export interface Trade {
  id: number;
  symbol: string;
  cusip: string;
  isInner: Boolean;
  isOption: Boolean;
  isOptionRoll: Boolean;
  isOptionAssignment: Boolean;
  isOptionExpiration: Boolean;
  instrumentType: string;
  tradeType: string;
  positionSize: number;
  netProfit: number;
  totalFees: number;
  percentReturn: number;
  startDate: string;
  endDate: string;
  quantity: number;
  averageOpeningPrice: number;
  averageClosingPrice: number;
  innerTradeIds: number[];
  tdaAccountId: string;
}

/* Returns the end date of a trade. That's the date when the trade was closed/completed */
export function tradeDate(trade: Trade): Date {
  const { endDate } = trade;
  return parse(endDate, "yyyy-MM-dd", new Date());
}

export function tradeEndDate(trade: Trade): Date {
  return tradeDate(trade);
}

export function tradeStartDate(trade: Trade): Date {
  const { startDate } = trade;
  return parse(startDate, "yyyy-MM-dd", new Date());
}

export function humanSymbol(trade: Trade): string {
  const { symbol } = trade;
  return trade.instrumentType === "OPTION"
    ? new OptionSymbolParser(symbol).toHuman()
    : symbol;
}

export function isShort(trade: Trade): boolean {
  const { tradeType } = trade;
  return tradeType === "SHORT";
}

export function shortOutcome(trade: Trade): string {
  const { isOptionExpiration, isOptionAssignment } = trade;
  return isOptionExpiration
    ? "Expired"
    : isOptionAssignment
      ? "Assigned"
      : "Closed";
}

export function longOutcome(trade: Trade): string {
  const { isOptionExpiration, isOptionAssignment, symbol, quantity } = trade;
  const optionsParser = new OptionSymbolParser(symbol);
  const action = isShort(trade)
    ? optionsParser.type() === "CALL"
      ? "sold"
      : "bought"
    : null;
  const netQuantity = quantity * 100;
  const underlying = optionsParser.underlying();
  return isOptionExpiration
    ? "Option expired worthless"
    : isOptionAssignment
      ? `Option assignment (${netQuantity} shares of ${underlying} ${action} at ${formatAsCurrency(
          optionsParser.strikePrice(),
        )})`
      : "Position closed";
}

export function isLong(trade: Trade): boolean {
  return !isShort(trade);
}
