import { isEmpty } from "lodash";

declare global {
  interface ReadonlySet<T> {
    map<R>(fn: (t: T) => R): Set<R>;

    includes(item: T): boolean;

    toArray(): T[];

    any(): boolean;

    none(): boolean;

    asyncMap<R>(fn: (t: T) => Promise<R>): Promise<R[]>;

    asyncForEach<R>(fn: (t: T) => Promise<void>): Promise<void>;
  }

  interface Set<T> {
    map<R>(fn: (t: T) => R): Set<R>;

    includes(item: T): boolean;

    toArray(): T[];

    find(
      predicate: (value: T, index: number, obj: T[]) => boolean,
    ): T | undefined;

    filter(
      predicate: (value: T, index: number, array: T[]) => unknown,
      thisArg?: any,
    ): Set<T>;

    removeElementAt(index: number): Set<T>;

    any(): boolean;

    none(): boolean;

    asyncMap<R>(fn: (t: T) => Promise<R>): Promise<R[]>;

    asyncForEach<R>(fn: (t: T) => Promise<void>): Promise<void>;

    asyncForEach<R>(fn: (t: T) => Promise<void>): Promise<void>;
  }

  interface Array<T> {
    toSet(): Set<T>;

    any(): boolean;

    none(): boolean;

    removeElementAt(index: number): T[];

    asyncForEach<R>(fn: (t: T) => Promise<void>): Promise<void>;

    asyncMap<R>(fn: (t: T) => Promise<R>): Promise<R[]>;

    asyncFlatMap<R>(fn: (t: T) => Promise<R[]>): Promise<R[]>;

    asyncFilter(fn: (t: T, index: number) => Promise<boolean>): Promise<T[]>;
  }

  interface String {
    then<T>(fn: (str: string) => T): T;
  }
}

String.prototype.then = function <T>(fn: (str: string) => T): T {
  return fn(this as string);
};

/** Copies the provided array into a new Set */
Array.prototype.toSet = function <T>(): Set<T> {
  return new Set(this);
};

Array.prototype.asyncMap = async function <T, R>(
  asyncFn: (val: T) => Promise<R>,
): Promise<R[]> {
  return Promise.all(this.map(asyncFn) as Promise<R>[]);
};

Array.prototype.asyncFilter = async function <T>(
  predicate: (val: T, index: number) => Promise<boolean>,
): Promise<T[]> {
  const results = await Promise.all(this.map(predicate));
  return this.filter((_v, index) => results[index]);
};

Array.prototype.asyncForEach = async function (asyncFn): Promise<void> {
  await this.asyncMap(asyncFn);
};

Array.prototype.asyncFlatMap = async function <T, R>(
  asyncFn: (t: T) => Promise<R[]>,
): Promise<R[]> {
  return (await this.asyncMap(asyncFn)).flat();
};

// returns true if the provided array is not empty
Array.prototype.any = function (): boolean {
  return !isEmpty(this);
};

Array.prototype.removeElementAt = function <T>(index: number): T[] {
  return this.filter((_, i) => i !== index);
};

Array.prototype.none = function (): boolean {
  return isEmpty(this);
};

/** Maps over the provided set */
Set.prototype.map = function <P, Q>(fn: (v: P) => Q): Set<Q> {
  return Array.from(this, fn).toSet();
};

Set.prototype.filter = function <T>(
  predicate: (value: T, index: number, array: T[]) => unknown,
  thisArg?: any,
): Set<T> {
  return Array.from<T>(this).filter(predicate, thisArg).toSet();
};

Set.prototype.removeElementAt = function <T>(index: number): Set<T> {
  return Array.from<T>(this).removeElementAt(index).toSet() as Set<T>;
};

Set.prototype.asyncMap = async function <P, Q>(
  asyncFn: (v: P) => Promise<Q>,
): Promise<Q[]> {
  return Promise.all(this.map(asyncFn) as Set<Promise<Q>>);
};

Set.prototype.asyncForEach = async function (asyncFn): Promise<void> {
  await this.asyncMap(asyncFn);
};

Set.prototype.toArray = function <T>(): T[] {
  return Array.from<T>(this);
};

Set.prototype.any = function (): boolean {
  return !isEmpty(this);
};

Set.prototype.none = function (): boolean {
  return isEmpty(this);
};

Set.prototype.find = function <T>(
  predicate: (value: T, index: number, obj: T[]) => boolean,
): T | undefined {
  return Array.from<T>(this).find(predicate);
};

Set.prototype.includes = function (item): boolean {
  return this.has(item);
};

export function toMap<V>(obj: Record<string, V>): ReadonlyMap<string, V> {
  return new Map(Object.entries(obj));
}
