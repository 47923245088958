import { EquityPosition } from "../equitiesTable";
import { PositionType } from "../models/positionType";
import {
  Instrument,
  InstrumentAssetType,
  OptionInstrumentType,
} from "../models/Types";
import { OptionPosition } from "../optionsTable";
import { OptionSymbolParser } from "./optionSymbolParser";
import { format } from "date-fns";

export interface AccountPosition {
  shortQuantity: number;
  averagePrice: number;
  currentDayProfitLoss: number;
  currentDayProfitLossPercentage: number;
  longQuantity: number;
  settledLongQuantity: number;
  settledShortQuantity: number;
  instrument: Instrument;
  marketValue: number;
  maintenanceRequirement?: number;
  lastPrice: number;
  // this is a purely presentation related field that was added here so we can keep track of the
  // previous last price and determine whether the price is going up or down. That way we can color
  // animate price updates similarly to how TOS works.
  previousPrice?: number;
}

function filterPositions(
  positions: AccountPosition[],
  instrumentAssetType: InstrumentAssetType,
): AccountPosition[] {
  return positions.filter(
    (p) => p.instrument.assetType === InstrumentAssetType[instrumentAssetType],
  );
}

function isOption({ instrument: { assetType } }: AccountPosition) {
  return assetType === InstrumentAssetType[InstrumentAssetType.OPTION];
}

function checkIsOption(position: AccountPosition) {
  if (!isOption(position)) {
    throw new Error("Position instrument type is not option");
  }
}

function checkIsEquity(position: AccountPosition) {
  if (
    position.instrument.assetType !==
    InstrumentAssetType[InstrumentAssetType.EQUITY]
  ) {
    throw new Error("Position instrument type is not equity");
  }
}

export function filterEquities(
  positions: AccountPosition[],
): AccountPosition[] {
  return filterPositions(positions, InstrumentAssetType.EQUITY);
}

export function filterOptions(positions: AccountPosition[]): AccountPosition[] {
  return filterPositions(positions, InstrumentAssetType.OPTION);
}

export function positionNetQuantity(position: AccountPosition): number {
  return position.longQuantity - position.shortQuantity;
}

/** Returns the total position value in USD */
export function positionSize(position: AccountPosition): number {
  return position.lastPrice * positionNetQuantity(position);
}

/** Returns the option position strike price */
export function optionStrikePrice(position: AccountPosition): number {
  checkIsOption(position);
  const optionSymbol = position.instrument.symbol;
  const symbolParser = new OptionSymbolParser(optionSymbol);
  return symbolParser.strikePrice();
}

/**
 * Returns the symbol for the provided position, suitable for using with TDA
 * websocket service, eg.: ".SPY220312C130"
 */
export function positionSymbol(position: AccountPosition): string {
  const { instrument } = position;
  if (isOption(position)) {
    // Option symbols are slightly different from instrument.symbol
    const expiration = optionExpiration(position);
    const strikePrice = optionStrikePrice(position);
    const dateFmt = format(expiration, "yyMMdd");
    const putCall = instrument.putCall === "CALL" ? "C" : "P";
    return `.${instrument.underlyingSymbol}${dateFmt}${putCall}${strikePrice}`;
  } else {
    return instrument.symbol;
  }
}

/** Returns the option position expiration date */
export function optionExpiration(position: AccountPosition): Date {
  checkIsOption(position);
  const optionSymbol = position.instrument.symbol;
  const symbolParser = new OptionSymbolParser(optionSymbol);
  return symbolParser.expiration();
}

/** Returns true if this is an options short position */
export function optionIsShortPosition(position: AccountPosition): boolean {
  checkIsOption(position);
  return positionNetQuantity(position) < 0;
}

/** Returns the amount in USD of the unrealized profit or loss on the provided equity position */
export function equityUnrealizedNetProfitOrLoss(
  position: AccountPosition,
): number {
  checkIsEquity(position);
  const { averagePrice, lastPrice, longQuantity, shortQuantity } = position;
  const netAmount = longQuantity - shortQuantity;
  return +netAmount * lastPrice - +netAmount * averagePrice;
}

/** Returns the amount in USD of the unrealized profit or loss on the provided option position */
export function optionUnrealizedNetProfitOrLoss(
  position: AccountPosition,
): number {
  checkIsOption(position);
  const { averagePrice, lastPrice, longQuantity, shortQuantity } = position;
  const netAmount = longQuantity - shortQuantity;
  return +netAmount * lastPrice * 100 - +netAmount * averagePrice * 100;
}

/**
 * If this is a short position, returns the total cash collateral held for this option position,
 * otherwise zero.
 */
export function optionCollateral(position: AccountPosition): number {
  checkIsOption(position);
  const quantity = positionNetQuantity(position);
  const strike = optionStrikePrice(position);
  return optionIsShortPosition(position)
    ? Math.abs(quantity * strike * 100)
    : 0;
}

export function accountPositionToEquity(
  position: AccountPosition,
): EquityPosition {
  const symbol = position.instrument.symbol;
  const quantity = position.longQuantity - position.shortQuantity;
  const type = quantity > 0 ? PositionType.LONG : PositionType.SHORT;
  const price = +position.lastPrice;
  const avgPrice = position.averagePrice;
  const plUsd = quantity * price - quantity * avgPrice;
  const plPercent = (quantity * price) / (quantity * avgPrice) - 1;
  return { symbol, type, quantity, price, avgPrice, plUsd, plPercent };
}

/* Returns whether the provided option position is in the money */
function isOptionITM(position: AccountPosition): boolean {
  const contract = position.instrument.putCall;
  const underlyingLastPrice = position.instrument.underlyingLastPrice;
  if (!underlyingLastPrice) {
    return false;
  } else {
    const strikePrice = optionStrikePrice(position);
    if (contract === OptionInstrumentType[OptionInstrumentType.PUT]) {
      return underlyingLastPrice <= strikePrice;
    } else {
      return underlyingLastPrice >= strikePrice;
    }
  }
}

export function accountPositionToOption(
  position: AccountPosition,
): OptionPosition {
  const symbol = position.instrument.symbol;
  const optionParser = new OptionSymbolParser(symbol);
  const terminalSymbol = optionParser.toTerminalInstrument();
  const humanSymbol = optionParser.toHuman();
  const { averagePrice, lastPrice } = position;
  const quantity = positionNetQuantity(position);
  const isShort = optionIsShortPosition(position);
  const strike = optionStrikePrice(position);
  const expiration = optionExpiration(position);
  const itm = isOptionITM(position);
  const pnlDollars = optionUnrealizedNetProfitOrLoss(position);
  const type = isShort ? PositionType.SHORT : PositionType.LONG;
  const maxReturn = isShort ? (averagePrice * 100) / (strike * 100) : Infinity;
  return {
    terminalSymbol,
    symbol: humanSymbol,
    type,
    quantity,
    price: lastPrice,
    avgPrice: averagePrice,
    plUsd: pnlDollars,
    plPercent: maxReturn,
    expiration,
    isITM: itm,
  };
}
