import { format } from "d3-format";
import { formatDistance } from "date-fns";

// Formats a number with 2 decimal digits precision
export const priceFormat = format(".2f");

// Formats `12300.29` as "$ 1,230.00"
export const formatAsCurrency = format("($,.2f");

export const formatAsCurrencyAbbreviated = format("($,.2s");

// Mimcs Rails' time_ago_in_words
export function timeAgoInWords(pastDate: Date): string {
  return formatDistance(pastDate, new Date(), { addSuffix: true });
}

export function formatAsPercentage(num: number): string {
  return num == Infinity ? "∞" : format(".2%")(num);
}
