import { format } from "date-fns";
import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatAsCurrency } from "./util/Format";
import CustomAmountTooltip from "./customAmountTooltip";
import { ChartMargins } from "./models/chartMargins";

const defaultDateFormatter = (date: Date) =>
  date.getMonth() === 0 ? format(date, "yy") : format(date, "MMM");

export type PnLBarChartProps = {
  width?: number;
  height?: number;
  dateFormatter?: (_: Date) => string;
  tooltipDateFormatter?: (_: Date) => string;
  data: { date: Date; value: number }[];
  margin?: ChartMargins;
};

const PnLBarChart = ({
  data,
  width = 1000,
  height = 600,
  dateFormatter = defaultDateFormatter,
  tooltipDateFormatter = defaultDateFormatter,
  margin = { top: 5, right: 30, left: 25, bottom: 5 },
}: PnLBarChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={width} height={height} data={data} margin={margin}>
        <CartesianGrid strokeDasharray="3 3" stroke="currentColor" />
        <XAxis
          dataKey="date"
          tickFormatter={dateFormatter}
          tick={{ fill: "currentColor" }}
        />
        <YAxis
          allowDecimals={false}
          tickFormatter={formatAsCurrency}
          tick={{ fill: "currentColor" }}
        />
        <Tooltip
          content={<CustomAmountTooltip dateFormatter={tooltipDateFormatter} />}
        />
        <ReferenceLine y={0} stroke="#999" />
        <Legend formatter={() => "Net P/L"} />
        <Bar dataKey="value" fill="#8884d8">
          activeBar={<Rectangle fill="pink" stroke="blue" />}
          {data.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={data[index].value > 0 ? "#82ca9d" : "#f65b5b"}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
export default PnLBarChart;
