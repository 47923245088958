import React, { useEffect, useState } from "react";
import HusklyAccountsApiClient from "./api/husklyAccountsApiClient";
import AnimatedNumber from "animated-number-react";
import { formatAsCurrency } from "./util/Format";
import selectAccountById from "./util/accountSelector";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/solid";

/**
 * If `selectedAccountId` is provided, displays the balance for that specific
 * account, otherwise the joint balance of all available accounts.
 */
const AccountBalance = ({
  selectedAccountId: _selectedAccountId,
}: {
  selectedAccountId?: string;
}) => {
  const [privacyModeEnabled, setPrivacyModeEnabled] = useState(
    localStorage.getItem("privacyModeEnabled") === "true",
  );
  const selectedAccountId =
    _selectedAccountId || localStorage.getItem("selectedAccountId");
  const [balance, setBalance] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const accountDetailsFetcher = new HusklyAccountsApiClient();
      const accounts = await accountDetailsFetcher.requestAccounts();
      const selectedAccount = selectAccountById(accounts, selectedAccountId);
      return selectedAccount?.balance || 0;
    };
    fetchData().then(setBalance).catch(console.error);
  }, []);
  const togglePrivacyMode = () => {
    setPrivacyModeEnabled(!privacyModeEnabled);
    localStorage.setItem("privacyModeEnabled", String(!privacyModeEnabled));
    // to make sure other components can listen and react to this elsewhere in the page
    window.dispatchEvent(new Event("storage"));
  };
  return (
    <h2 className="font-medium font-mono text-3xl dark:text-gray-300 flex">
      {privacyModeEnabled ? (
        <LockClosedIcon
          className="w-8 mr-4 cursor-pointer"
          onClick={togglePrivacyMode}
        />
      ) : (
        <LockOpenIcon
          className="w-8 mr-4 cursor-pointer"
          onClick={togglePrivacyMode}
        />
      )}
      {privacyModeEnabled ? (
        "$••••••.••"
      ) : (
        <AnimatedNumber value={balance} formatValue={formatAsCurrency} />
      )}
    </h2>
  );
};
export default AccountBalance;
