import * as React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import HusklyLogo from "./husklyLogo";
import { format } from "date-fns";
import CompositePnLChart from "./compositePnLChart";
import { times } from "lodash";

const LandingPage = () => {
  const [optionsFocused, setOptionsFocused] = useState(false);
  const sampleChartData = [
    {
      date: new Date("2020-05-01T07:00:00.000Z"),
      barValue: -250.75999999999996,
      lineValue: -250.75999999999996,
    },
    {
      date: new Date("2020-06-01T07:00:00.000Z"),
      barValue: 2458.52,
      lineValue: 2207.76,
    },
    {
      date: new Date("2020-07-01T07:00:00.000Z"),
      barValue: 3636.3100000000013,
      lineValue: 5844.0700000000015,
    },
    {
      date: new Date("2020-08-01T07:00:00.000Z"),
      barValue: -2514.6299999999987,
      lineValue: 3329.440000000003,
    },
    {
      date: new Date("2020-09-01T07:00:00.000Z"),
      barValue: -7269.6800000000085,
      lineValue: -3940.2400000000057,
    },
    {
      date: new Date("2020-10-01T07:00:00.000Z"),
      barValue: -15751.220000000003,
      lineValue: -19691.46000000001,
    },
    {
      date: new Date("2020-11-01T07:00:00.000Z"),
      barValue: -2500.5999999999995,
      lineValue: -22192.06000000001,
    },
    {
      date: new Date("2020-12-01T08:00:00.000Z"),
      barValue: -9545.45,
      lineValue: -31737.51000000001,
    },
    {
      date: new Date("2021-01-01T08:00:00.000Z"),
      barValue: 12756.480000000001,
      lineValue: -18981.030000000006,
    },
    {
      date: new Date("2021-02-01T08:00:00.000Z"),
      barValue: -52.46000000000106,
      lineValue: -19033.49000000001,
    },
    {
      date: new Date("2021-03-01T08:00:00.000Z"),
      barValue: 6899.81,
      lineValue: -12133.680000000008,
    },
    {
      date: new Date("2021-04-01T07:00:00.000Z"),
      barValue: -3244.3100000000004,
      lineValue: -15377.990000000009,
    },
    {
      date: new Date("2021-05-01T07:00:00.000Z"),
      barValue: 7149.500000000001,
      lineValue: -8228.490000000009,
    },
    {
      date: new Date("2021-06-01T07:00:00.000Z"),
      barValue: 17135.13,
      lineValue: 8906.639999999992,
    },
    {
      date: new Date("2021-07-01T07:00:00.000Z"),
      barValue: 5107.3,
      lineValue: 14013.939999999991,
    },
    {
      date: new Date("2021-08-01T07:00:00.000Z"),
      barValue: 24428.959999999995,
      lineValue: 38442.89999999999,
    },
    {
      date: new Date("2021-09-01T07:00:00.000Z"),
      barValue: -1757.130000000001,
      lineValue: 36685.76999999999,
    },
    {
      date: new Date("2021-10-01T07:00:00.000Z"),
      barValue: 17932.96,
      lineValue: 54618.72999999999,
    },
    {
      date: new Date("2021-11-01T07:00:00.000Z"),
      barValue: -42301.76,
      lineValue: 12316.969999999987,
    },
    {
      date: new Date("2021-12-01T08:00:00.000Z"),
      barValue: 559.49,
      lineValue: 12876.459999999986,
    },
    {
      date: new Date("2022-01-01T08:00:00.000Z"),
      barValue: -3301.19,
      lineValue: 9575.269999999986,
    },
    {
      date: new Date("2022-04-01T07:00:00.000Z"),
      barValue: 65072.009999999995,
      lineValue: 74647.27999999998,
    },
    {
      date: new Date("2022-05-01T07:00:00.000Z"),
      barValue: 4436.210000000003,
      lineValue: 79083.48999999999,
    },
    {
      date: new Date("2022-06-01T07:00:00.000Z"),
      barValue: 3968.2599999999998,
      lineValue: 83051.74999999999,
    },
    {
      date: new Date("2022-07-01T07:00:00.000Z"),
      barValue: 11790.929999999998,
      lineValue: 94842.67999999998,
    },
    {
      date: new Date("2022-08-01T07:00:00.000Z"),
      barValue: 9579.150000000001,
      lineValue: 104421.82999999999,
    },
    {
      date: new Date("2022-09-01T07:00:00.000Z"),
      barValue: -142279.24,
      lineValue: -37857.41,
    },
    {
      date: new Date("2022-10-01T07:00:00.000Z"),
      barValue: 20327.569999999992,
      lineValue: -17529.84000000001,
    },
    {
      date: new Date("2022-11-01T07:00:00.000Z"),
      barValue: 5895.439999999999,
      lineValue: -11634.400000000012,
    },
    {
      date: new Date("2022-12-01T08:00:00.000Z"),
      barValue: 10002.209999999994,
      lineValue: -1632.1900000000187,
    },
    {
      date: new Date("2023-01-01T08:00:00.000Z"),
      barValue: -31395.780000000002,
      lineValue: -33027.97000000002,
    },
    {
      date: new Date("2023-06-01T07:00:00.000Z"),
      barValue: 32353.210000000017,
      lineValue: -674.7600000000057,
    },
    {
      date: new Date("2023-07-01T07:00:00.000Z"),
      barValue: 20555.85,
      lineValue: 19881.089999999993,
    },
    {
      date: new Date("2023-08-01T07:00:00.000Z"),
      barValue: 10147.759999999991,
      lineValue: 30028.849999999984,
    },
    {
      date: new Date("2023-09-01T07:00:00.000Z"),
      barValue: 4018.5599999999968,
      lineValue: 34047.40999999998,
    },
    {
      date: new Date("2023-10-01T07:00:00.000Z"),
      barValue: 4281.150000000001,
      lineValue: 38328.55999999998,
    },
    {
      date: new Date("2023-11-01T07:00:00.000Z"),
      barValue: 26622.509999999995,
      lineValue: 64951.06999999998,
    },
    {
      date: new Date("2023-12-01T08:00:00.000Z"),
      barValue: 21725.989999999987,
      lineValue: 86677.05999999997,
    },
    {
      date: new Date("2024-01-01T08:00:00.000Z"),
      barValue: 25077.979999999992,
      lineValue: 111755.03999999996,
    },
    {
      date: new Date("2024-02-01T08:00:00.000Z"),
      barValue: 12086.599999999995,
      lineValue: 123841.63999999996,
    },
  ];
  return (
    <div
      className="min-h-screen"
      style={{
        background:
          "linear-gradient(223deg, rgb(10 13 20) 0%, rgb(24, 40, 72) 100%)",
      }}
    >
      <div className="max-w-7xl mx-auto pb-8">
        <div className="p-8 flex justify-center">
          <div className="flex flex-col justify-center items-center px-2">
            <h1 className="mx-auto mb-12 flex items-center">
              <div className="fill-teal-700 w-12 h-14 mr-4">
                <HusklyLogo />
              </div>
              <span className="text-3xl font-extrabold leading-loose text-center bg-clip-text text-transparent bg-gradient-to-r from-violet-500 to-teal-300">
                Huskly Finance
              </span>
            </h1>
            <h2 className="text-5xl font-extrabold text-center mb-4 text-white">
              Trade options with confidence
            </h2>
            <h3 className="text-4xl text-center mb-10 text-white">
              Built by traders, for traders
            </h3>
            <div className="mx-auto mb-8 flex flex-col">
              <a
                href="/signup"
                className="py-3 px-8 mb-2 border border-transparent text-xl rounded-full text-black bg-tda-500 hover:bg-tda-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 font-semibold whitespace-nowrap"
              >
                Create an account
              </a>
              <small className="text-center">No credit card required</small>
            </div>
            <section className="w-full my-20 flex flex-wrap items-center">
              <video
                autoPlay
                controls
                loop
                muted
                playsInline
                className="mx-auto md:w-full rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 border-2 border-gray-700"
              >
                <source
                  src="https://huskly.s3.us-west-2.amazonaws.com/huskly-terminal-demo_23.mp4"
                  type="video/mp4"
                />
              </video>
            </section>
            <section className="w-full my-20 flex flex-wrap justify-center">
              <div className="relative w-full md:w-[680px] h-80 max-w-full md:mr-8 mb-8 sm:mb-0">
                <ShortOptionsPreviewPanel
                  focused={optionsFocused}
                  onHoverStart={() => setOptionsFocused(true)}
                  onHoverEnd={() => setOptionsFocused(false)}
                />
                <LongOptionsPreviewPanel focused={!optionsFocused} />
              </div>
              <div className="md:w-80 self-center text-center text-white">
                <h4 className="font-semibold text-xl mb-4">
                  Your stocks and options at a glance
                </h4>
                <p>
                  Whether you trade long or short options, you can view your
                  covered calls and cash secured puts in a single place.
                </p>
              </div>
            </section>
            <section className="w-full my-20 flex flex-wrap justify-center">
              <div className="h-72 md:h-96 w-fit grow bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg p-2 md:p-4 border-[1px] border-gray-700 text-white md:mr-8 mb-8">
                <CompositePnLChart
                  data={sampleChartData}
                  margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
                  tooltipDateFormatter={(d) => format(d, "MMM/yyyy")}
                />
              </div>
              <div className="md:w-80 self-center text-center text-white">
                <h4 className="font-semibold text-xl mb-4">
                  Historical P/L charts
                </h4>
                <p>
                  Visualize your trading performance over time with our
                  extensive charts. Track your evolution and identify patterns.
                </p>
              </div>
            </section>
            <section className="w-full my-20 flex flex-wrap justify-center max-w-full">
              <div className="md:w-[680px] md:mr-8 mb-8 sm:mb-0 overflow-auto w-full">
                <table className="w-full divide-y divide-gray-200 dark:divide-gray-800 rounded-lg shadow-lg overflow-hidden">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr className="flex flex-col flex-no wrap table-row">
                      <th
                        scope="col"
                        className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-center"
                      >
                        <span className="cursor-pointer">Date</span>
                      </th>
                      <th
                        scope="col"
                        className="p-2 text-xs whitespace-nowrap font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
                      >
                        <span className="cursor-pointer">Symbol</span>
                      </th>
                      <th
                        scope="col"
                        className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
                      >
                        <span className="cursor-pointer">Type</span>
                      </th>
                      <th
                        scope="col"
                        className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
                      >
                        <span className="cursor-pointer">Net P/L</span>
                      </th>
                      <th
                        scope="col"
                        className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
                      >
                        <span className="cursor-pointer">% Return</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-300 dark:divide-gray-800 flex-1 sm:flex-none">
                    {times(5).map((i) => (
                      <tr
                        className="flex flex-col flex-nowrap table-row"
                        key={i}
                      >
                        <td className="p-2 whitespace-nowrap">
                          <div className="h-3 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                        </td>
                        <td className="p-2 whitespace-nowrap flex justify-center">
                          <div className="h-3 rounded-full bg-gray-300 dark:bg-gray-700 w-full"></div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div
                            className={
                              `h-3 rounded-full w-full float-right ` +
                              (i % 2 === 0
                                ? "bg-yellow-800 dark:bg-yellow-400"
                                : "bg-blue-900")
                            }
                          ></div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div
                            className={
                              `h-3 rounded-full w-12 float-right ` +
                              (i === 2 ? "bg-rose-700" : "bg-teal-800")
                            }
                          ></div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div
                            className={
                              `h-3 rounded-full w-12 float-right ` +
                              (i === 2 ? "bg-rose-700" : "bg-teal-800")
                            }
                          ></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="md:w-80 self-center text-center text-white">
                <h4 className="font-semibold text-xl mb-4">
                  Automated trade journaling
                </h4>
                <p>
                  Ditch the spreadsheets and let Huskly track your trades and
                  P/L. Focus on sharpening your edge.
                </p>
              </div>
            </section>
            <div className="fixed top-5 right-5">
              <a
                type="submit"
                className="border border-white rounded py-2 px-4 text-gray-300 bg-[#0a0d14]"
                href="/login"
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="border-t-2 border-gray-900 pt-6 bg-black/20">
      <div className="max-w-7xl mx-auto">
        <div className="py-8 px-4">
          <div className="py-4">
            <div className="flex justify-between">
              <div className="">
                <h4 className="mb-3">
                  <div className="text-2xl text-gray-300 font-medium fill-teal-700 flex items-center">
                    <HusklyLogo className="inline-block mr-2 h-12" />
                    Huskly Finance
                  </div>
                </h4>
                <p className="text-gray-300 mb-6">
                  The modern options trader dashboard.
                </p>
              </div>
              <div>
                <ul className="list-unstyled text-right">
                  <li>
                    <a href="/pricing" className="text-gray-300">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a href="/contact" className="text-gray-300">
                      Contact
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policy" className="text-gray-300">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="/terms-of-service" className="text-gray-300">
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a href="/content" className="text-gray-300">
                      Knowledge Base
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-3 text-gray-400 dark:text-gray-300">
        © 2024&nbsp;
        <a href="https://huskly.finance/" className="dark:text-gray-400">
          Huskly Finance
        </a>
      </div>
    </footer>
  );
};

const ShortOptionsPreviewPanel = ({
  focused,
  onHoverStart,
  onHoverEnd,
}: {
  focused: boolean;
  onHoverStart: () => void;
  onHoverEnd: () => void;
}) => {
  return (
    <motion.div
      onClick={() => (focused ? onHoverEnd() : onHoverStart())}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      initial="unfocused"
      animate={focused ? "focused" : "unfocused"}
      variants={{
        unfocused: { scale: 0.9 },
        focused: { scale: 1, transition: { duration: 0.3 } },
      }}
      className="bg-gray-800 rounded-lg shadow-lg p-6 my-2 w-full border-[1px] border-gray-700 scale-90 absolute overflow-auto"
    >
      <h3 className="font-semibold text-lg text-gray-200 dark:text-gray-300 mb-4">
        <span className="px-3 py-1 inline-flex font-semibold rounded-full bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-400 mr-2">
          Short
        </span>{" "}
        Options Positions
      </h3>
      <table className="w-full divide-y divide-gray-200 dark:divide-gray-800 rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gray-50 dark:bg-gray-700">
          <tr className="flex flex-col flex-no wrap table-row">
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-center"
            >
              <span className="cursor-pointer">symbol</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs whitespace-nowrap font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">Time to exp.</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">qty</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">price</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">avg. price</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">P/L $</span>
            </th>
            <th
              scope="col"
              className="p-2 pr-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">P/L %</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-300 dark:divide-gray-800 flex-1 sm:flex-none">
          <tr className="flex flex-col flex-nowrap table-row">
            <td className="p-2 whitespace-nowrap dark:bg-gray-700 bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 hover:transition-all">
              <div className="flex justify-center">
                <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-center">
                  SNAP 02/23/24 CALL 11
                </div>
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                3 days
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                1
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                $0.29
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                $0.17
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-sm font-medium text-right text-rose-800 dark:text-rose-500">
                ($12.00)
              </div>
            </td>
            <td className="p-2 pr-4 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                1.59%
              </div>
            </td>
          </tr>
          <tr className="flex flex-col flex-nowrap table-row">
            <td className="p-2 whitespace-nowrap dark:bg-gray-700 bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 hover:transition-all">
              <div className="flex justify-center">
                <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-center">
                  AMZN 02/23/24 CALL 170
                </div>
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                3 days
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                1
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                $20.35
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                $19.20
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-sm font-medium text-right text-teal-800 dark:text-teal-500">
                $135.00
              </div>
            </td>
            <td className="p-2 pr-4 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                3.1%
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </motion.div>
  );
};

const LongOptionsPreviewPanel = ({ focused }: { focused: boolean }) => {
  return (
    <motion.div
      initial="focused"
      animate={focused ? "focused" : "unfocused"}
      variants={{
        unfocused: { scale: 0.9, top: 220 },
        focused: { scale: 1, top: 80, transition: { duration: 0.3 } },
      }}
      className="bg-gray-800 rounded-lg shadow-lg p-6 top-20 w-full border-[1px] border-gray-700 absolute overflow-auto"
    >
      <h3 className="font-semibold text-lg text-gray-200 dark:text-gray-300 mb-4">
        <span className="px-3 py-1 inline-flex font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 mr-2">
          Long
        </span>{" "}
        Option Positions
      </h3>
      <table className="w-full divide-y divide-gray-200 dark:divide-gray-800 rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gray-50 dark:bg-gray-700">
          <tr className="flex flex-col flex-no wrap table-row">
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-center"
            >
              <span className="cursor-pointer">symbol</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs whitespace-nowrap font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">Time to exp.</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">qty</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">price</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">avg. price</span>
            </th>
            <th
              scope="col"
              className="p-2 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">P/L $</span>
            </th>
            <th
              scope="col"
              className="p-2 pr-4 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
            >
              <span className="cursor-pointer">P/L %</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-300 dark:divide-gray-800 flex-1 sm:flex-none">
          <tr className="flex flex-col flex-nowrap table-row">
            <td className="px-1 py-2 whitespace-nowrap dark:bg-gray-700 bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 hover:transition-all">
              <div className="flex justify-center">
                <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-center">
                  GME 02/23/24 CALL 14
                </div>
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                3 days
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                2
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                $0.39
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                $0.29
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-sm font-medium text-right text-teal-800 dark:text-teal-500">
                $20.00
              </div>
            </td>
            <td className="p-2 pr-4 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                ∞
              </div>
            </td>
          </tr>
          <tr className="flex flex-col flex-nowrap table-row">
            <td className="px-1 py-2 whitespace-nowrap dark:bg-gray-700 bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 hover:transition-all">
              <div className="flex justify-center">
                <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-center">
                  NVDA 02/23/24 PUT 645
                </div>
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                3 days
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                1
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                $20.35
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                $19.20
              </div>
            </td>
            <td className="px-1 py-2 whitespace-nowrap">
              <div className="text-sm font-medium text-right text-teal-800 dark:text-teal-500">
                $135.00
              </div>
            </td>
            <td className="p-2 pr-4 whitespace-nowrap">
              <div className="text-xs font-medium text-gray-900 dark:text-gray-100 text-right">
                ∞
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </motion.div>
  );
};

export default LandingPage;
