import { AccountDetails } from "../api/husklyAccountsApiClient";

/**
 * If `selectedAccountId` is set, filter all accounts to return include the one
 * that matches the provided `selectedAccountId`, otherwise just return the first
 * account by default
 */
export default function selectAccountById(
  accounts: AccountDetails[],
  selectedAccountId?: string | null,
): AccountDetails | undefined {
  return selectedAccountId
    ? accounts.find(({ accountId }) => accountId === selectedAccountId)
    : accounts[0];
}
