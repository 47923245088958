import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import ReactRailsUJS from "react_ujs";
import CookieConsent from "@klaxit/cookie-consent";

Rails.start();
ActiveStorage.start();

ReactRailsUJS.useContext({
  AppRoutes: require("./components/appRoutes.tsx"),
  ThemeSelector: require("./components/themeSelector.tsx"),
  AppDropdownMenu: require("./components/appDropdownMenu.tsx"),
  AppDesktopNav: require("./components/appDesktopNav.tsx"),
  AccountBalance: require("./components/accountBalance.tsx"),
  FlashNotice: require("./components/flashNotice.tsx"),
  FlashAlert: require("./components/flashAlert.tsx"),
  SyncTradesPrompt: require("./components/syncTradesPrompt.tsx"),
});

const cc = new CookieConsent({
  title: "",
  description: `Huskly uses cookies to improve your experience.
                You can change this in the settings below, confirm
                your consent and accept all cookies or click “Reject” if you prefer to opt-out.`,
});
cc.on("change", (cc) => {
  console.log("change in cookie settings", cc.acceptedCategories);
  window.updateAnalytics(cc);
});
// to make sure it is loaded once.
window.updateAnalytics(cc);
