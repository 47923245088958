export type PeriodTypeApiParam = "day" | "month" | "year" | "ytd";
export type FrequencyTypeApiParam = "minute" | "daily" | "weekly" | "monthly";
export type FrequencyApiParam = 1 | 5 | 10 | 15 | 30 | 60;

export type OHLC = {
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
};

export type StockPrice = { date: Date } & OHLC;

export interface Stock {
  company: string;
  symbol: string;
  sector: string;
  marketCap: number;
  pe?: number; // P/E Ratio
  exchange: string;
  description?: string;
  earningsAnnouncement: Date;
}

// ActiveRecord types below
export interface Security {
  symbol: string;
  lastPrice: StockPrice;
}

export interface StockOption {
  type: string;
  strike: number;
  last: number;
  change: number;
  percent_change: number;
  volume: number;
  open_interest: number;
  bid: number;
  ask: number;
  expiration: Date;
  currency: string;
}

export enum InstrumentAssetType {
  EQUITY,
  OPTION,
  INDEX,
  MUTUAL_FUND,
  CASH_EQUIVALENT,
  FIXED_INCOME,
  CURRENCY,
}

export enum OptionInstrumentType {
  PUT,
  CALL,
}

export interface Instrument {
  assetType: string;
  cusip: string;
  symbol: string;
  description?: string;
  putCall?: string;
  underlyingSymbol?: string;
  underlyingLastPrice?: number;
}
