import React from "react";
import { Trade } from "./models/Trade";
import TradeDetailsGrid from "./tradeDetailsGrid";

interface TradeDetailsProps {
  trade: Trade;
  innerTrades?: Trade[];
}

const TradeDetailsComponent = ({
  trade,
  innerTrades = [],
}: TradeDetailsProps) => {
  return (
    <div className="bg-slate-100 dark:bg-gray-800 min-h-screen">
      <div className="max-w-xl mx-auto py-8">
        <h1 className="text-3xl font-bold mb-8">
          {trade.isOptionRoll ? "Option roll details" : "Trade details"}
        </h1>
        <TradeDetailsGrid trade={trade} />
        {trade.isOptionRoll && (
          <>
            <h2 className="text-3xl mb-8 font-semibold">
              Roll sub-trade details
            </h2>
            {innerTrades.map((innerTrade) => (
              <TradeDetailsGrid key={innerTrade.id} trade={innerTrade} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default TradeDetailsComponent;
