import React, { RefObject } from "react";
import { formatAsCurrency } from "./util/Format";

interface AnimatedPriceProps {
  price: number;
  className: string;
}

export default class AnimatedPrice extends React.Component<
  AnimatedPriceProps,
  any
> {
  static defaultProps = {
    className: "",
  };

  private readonly lastPriceElem: RefObject<HTMLSpanElement> =
    React.createRef();

  componentDidUpdate(prevProps: AnimatedPriceProps) {
    const prevPrice = prevProps.price;
    const currPrice = this.props.price;
    if (prevPrice !== currPrice) {
      const klasses =
        currPrice > prevPrice
          ? [
              "text-teal-800",
              "bg-teal-800/10",
              "dark:text-lime-500",
              "dark:bg-lime-500/10",
            ]
          : [
              "text-rose-800",
              "bg-rose-800/10",
              "dark:text-rose-500",
              "dark:bg-rose-500/10",
            ];
      const animatedNumber = this.lastPriceElem.current!;
      animatedNumber.classList.add(...klasses);
      setTimeout(() => {
        animatedNumber.classList.remove(...klasses);
      }, 500);
    }
  }

  render() {
    const { className, price } = this.props;
    return (
      <span
        className={`${className} transition-colors duration-500 p-2 rounded`}
        ref={this.lastPriceElem}
      >
        {formatAsCurrency(price)}
      </span>
    );
  }
}
