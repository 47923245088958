import {
  BellAlertIcon,
  ChartBarSquareIcon,
  DocumentTextIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import React from "react";

const AppDesktopNav = ({
  selectedItem,
}: {
  selectedItem: "dashboard" | "transactions" | "metrics" | "alerts";
}) => {
  const selectedItemKlass = "text-gray-100 bg-primary-800";
  return (
    <nav className="hidden md:flex flex-wrap items-center space-x-2">
      <div
        className={
          `py-2 px-3 flex items-center justify-center rounded-lg dark:text-gray-100 dark:hover:bg-gray-800 hover:bg-gray-100 ` +
          (selectedItem === "dashboard" ? selectedItemKlass : "")
        }
      >
        <HomeIcon className="h-4 mr-3 inline-block" />
        <a className="font-medium" href="/">
          Dashboard
        </a>
      </div>
      <div
        className={
          `py-2 px-3 flex items-center justify-center rounded-lg dark:text-gray-100 dark:hover:bg-gray-800 hover:bg-gray-100 ` +
          (selectedItem === "transactions" ? selectedItemKlass : "")
        }
      >
        <DocumentTextIcon className="h-4 mr-3 inline-block" />
        <a className="font-medium" href="/trades">
          Transactions
        </a>
      </div>
      <div
        className={
          `py-2 px-3 flex items-center justify-center rounded-lg dark:text-gray-100 dark:hover:bg-gray-800 hover:bg-gray-100 ` +
          (selectedItem === "metrics" ? selectedItemKlass : "")
        }
      >
        <ChartBarSquareIcon className="h-4 mr-3 inline-block" />
        <a className="font-medium" href="/metrics">
          Metrics
        </a>
      </div>
      <div
        className={
          `py-2 px-3 flex items-center justify-center rounded-lg dark:text-gray-100 dark:hover:bg-gray-800 hover:bg-gray-100 ` +
          (selectedItem === "alerts" ? selectedItemKlass : "")
        }
      >
        <a className="font-medium" href="/alerts">
          <BellAlertIcon className="h-4 mr-3 inline-block" />
          Alerts
        </a>
      </div>
    </nav>
  );
};

export default AppDesktopNav;
