import { formatAsPercentage } from "./util/Format";
import React from "react";
import {
  humanSymbol,
  isShort,
  shortOutcome,
  Trade,
  tradeDate,
} from "./models/Trade";
import { format } from "date-fns";
import CurrencyAmount from "./currencyAmount";
import LongShortPill from "./longShortPill";
import { PositionType } from "./models/positionType";
import useSortable from "./util/useSortable";

const TradeHistoryTable = ({ trades }: { trades: Trade[] }) => {
  // @ts-ignore
  const [sortedTrades, sortTableBy] = useSortable(trades, "date", "desc");
  return (
    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-800 rounded-lg overflow-hidden shadow-lg">
      <thead className="bg-gray-50 dark:bg-gray-700">
        <tr className="flex flex-nowrap table-row">
          <th
            // @ts-ignore
            onClick={() => sortTableBy("date")}
            scope="col"
            className="p-2 lg:px-6 lg:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-center"
          >
            <span className="cursor-pointer">date</span>
          </th>
          <th
            onClick={() => sortTableBy("symbol")}
            scope="col"
            className="p-2 lg:px-6 lg:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-center"
          >
            <span className="cursor-pointer">symbol</span>
          </th>
          <th
            onClick={() => sortTableBy("tradeType")}
            scope="col"
            className="p-2 lg:px-6 lg:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">type</span>
          </th>
          <th
            onClick={() => sortTableBy("netProfit")}
            scope="col"
            className="p-2 lg:px-6 lg:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">Net P/L</span>
          </th>
          <th
            onClick={() => sortTableBy("percentReturn")}
            scope="col"
            className="p-2 lg:px-6 lg:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">% Return</span>
          </th>
          <th
            onClick={() => sortTableBy("totalFees")}
            scope="col"
            className="p-2 lg:px-6 lg:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">Fees paid</span>
          </th>
          <th
            scope="col"
            className="p-2 lg:px-6 lg:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">outcome</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-800 flex-1 sm:flex-none">
        {sortedTrades.map((trade) => {
          const { netProfit, percentReturn, totalFees, id } = trade;
          const date = tradeDate(trade);
          const symbol = humanSymbol(trade);
          return (
            <tr
              key={trade.id}
              className="table-row flex flex-nowrap hover:bg-gray-200 dark:hover:bg-gray-950"
            >
              <td className="p-2 lg:px-6 lg:py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                  {format(date, "MM/dd/yyyy")}
                </div>
              </td>
              <td className="p-2 lg:px-6 lg:py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100">
                  <a href={`/trades/${id}`}>{symbol}</a>
                </div>
              </td>
              <td className="p-2 lg:px-6 lg:py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                  <LongShortPill
                    type={
                      isShort(trade) ? PositionType.SHORT : PositionType.LONG
                    }
                  />
                </div>
              </td>
              <td className="p-2 lg:px-6 lg:py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                  <CurrencyAmount amount={netProfit} />
                </div>
              </td>
              <td className="p-2 lg:px-6 lg:py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                  {formatAsPercentage(percentReturn)}
                </div>
              </td>
              <td className="p-2 lg:px-6 lg:py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                  <CurrencyAmount amount={-totalFees} />
                </div>
              </td>
              <td className="p-2 lg:px-6 lg:py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                  {shortOutcome(trade)}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default TradeHistoryTable;
