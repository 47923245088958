import { timeFormat } from "d3-time-format";
import {
  AccountPosition,
  filterEquities,
  filterOptions,
} from "../util/accountPosition";
import { Trade } from "../models/Trade";
import HusklyApiClient from "./husklyApiClient";
import { TdaAccount } from "../models/tdaAccount";
import "../util/collections";
import { compact } from "lodash";

export type ApiErrorResponse = { error: string };
export type AccountDetails = {
  accountId: string;
  balance: number;
  positions: AccountPosition[];
};

/** Makes API requests fetching data related to user account information */
export default class HusklyAccountsApiClient {
  constructor(private readonly apiClient = new HusklyApiClient()) {}

  private async requestRawAccountDetails(): Promise<
    TdaAccount[] | ApiErrorResponse
  > {
    const response = await fetch("/api/v1/account");
    return await response.json();
  }

  async requestTrades({
    startDate,
    endDate,
    tdaAccountId,
    symbol,
  }: {
    startDate: Date;
    endDate: Date;
    tdaAccountId?: string | null | undefined;
    symbol?: string | null | undefined;
  }): Promise<Trade[] | ApiErrorResponse> {
    const tf = timeFormat("%Y-%m-%d");
    const startDateString = tf(startDate);
    const endDateString = tf(endDate);
    const queryString = [
      `start_date=${startDateString}`,
      `end_date=${endDateString}`,
    ];
    if (tdaAccountId) {
      queryString.push(`tda_account_id=${tdaAccountId}`);
    }
    if (symbol) {
      queryString.push(`symbol=${symbol}`);
    }
    const response = await fetch(
      `/api/v1/transactions?${queryString.join("&")}`,
    );
    return await response.json();
  }

  /** Returns an arry with all accounts available for the current user */
  async requestAccounts(): Promise<AccountDetails[]> {
    const accountDetails = await this.requestRawAccountDetails();
    if (!accountDetails || "error" in accountDetails) {
      return [{ accountId: "", balance: 0, positions: [] }];
    }
    const { apiClient } = this;
    return await accountDetails.asyncMap(async ({ securitiesAccount }) => {
      const { positions = [], accountId } = securitiesAccount;
      const balance = securitiesAccount.currentBalances.liquidationValue || 0;
      const equities = filterEquities(positions);
      const options = filterOptions(positions);
      const quotes = await apiClient.fetchLastPrices(
        // Fetch quotes for both equity symbols and option underlying symbols
        new Set(
          compact([
            ...equities.map((e) => e.instrument.symbol),
            ...options.map((e) => e.instrument.symbol),
            ...options.map((e) => e.instrument.underlyingSymbol),
          ]),
        ),
      );
      positions.forEach((e) => {
        const { instrument } = e;
        const { underlyingSymbol, symbol } = instrument;
        e.lastPrice = quotes[symbol];
        // if it's an option position, also fetch the last price for the
        // underlying symbol, so we can eg: know if this option is ITM/OTM/ATM
        if (underlyingSymbol) {
          instrument.underlyingLastPrice = quotes[underlyingSymbol];
        }
      });
      return { accountId, balance, positions };
    });
  }
}
