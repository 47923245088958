import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
  startOfYear,
} from "date-fns";
import {
  groupBy,
  isEmpty,
  last,
  maxBy,
  minBy,
  sortBy,
  sum,
  sumBy,
} from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  AccountPosition,
  filterEquities,
  positionSize,
} from "./util/accountPosition";
import HusklyAccountsApiClient from "./api/husklyAccountsApiClient";
import { Trade, tradeEndDate, tradeStartDate } from "./models/Trade";
import PositionsPieChart from "./PositionsPieChart";
import CircularProgress from "./circularProgress";
import CardComponent from "./cardComponent";
import CompositePnLChart from "./compositePnLChart";
import CurrencyDollarIcon from "@heroicons/react/24/outline/CurrencyDollarIcon";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/outline";

type MonthlyPnLChartData = {
  // month
  date: Date;
  // net P/L for the month
  barValue: number;
  // cumulative P/L so far for the entire time period
  lineValue: number;
};
type EquityPositionsPieChartData = { name: string; value: number };

const TIME_PERIODS = ["All-time", "1Y", "YTD"];
type TimePeriod = (typeof TIME_PERIODS)[number];

const MetricsComponent = () => {
  const accountsApiClient = new HusklyAccountsApiClient();
  const [trades, setTrades] = useState<Trade[]>([]);
  const [positions, setPositions] = useState<AccountPosition[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  // Trading P/L amount for each individual month
  const [pnlByMonth, setPnlByMonth] = useState<MonthlyPnLChartData[]>([]);
  // Net P/L from the start date until end date
  const [cumulativePnl, setCumulativePnl] = useState(0);
  const [bestPnl, setBestPnl] = useState(0);
  const [worstPnl, setWorstPnl] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timePeriod, setTimePeriod] = useState<TimePeriod>("All-time");
  const [monthlyAveragePnl, setMonthlyAveragePnl] = useState(0);
  // Breakdown of equity positions by percentage of total account size (for pie chart)
  const [equityPositionPercentages, setEquityPositionPercentages] = useState<
    EquityPositionsPieChartData[]
  >([]);

  useEffect(() => {
    const fetchTrades = async () => {
      const response = await accountsApiClient.requestTrades({
        startDate: new Date(1970, 0, 1),
        endDate: new Date(),
      });
      if ("error" in response) {
        console.error("API request failed: " + response.error);
        setIsLoading(false);
      } else {
        const accounts = await accountsApiClient.requestAccounts();
        const positions = accounts.flatMap(({ positions }) => positions);
        setIsLoading(false);
        const trades = sortBy(
          response.filter(({ isInner }) => !isInner),
          tradeEndDate,
        );
        setTrades(trades);
        setPositions(positions);
      }
    };
    fetchTrades().catch(console.error);
  }, []);

  useEffect(() => {
    const tradesByMonth = groupBy(trades, (d) =>
      format(tradeEndDate(d), "yyyy-MM"),
    );
    const startDate = determineStartDate(timePeriod, trades);
    // end at the last day of the month of the newest trade
    const endDate = !isEmpty(trades)
      ? endOfMonth(tradeEndDate(last(trades)!))
      : new Date();
    const baseData = { startDate, endDate, tradesByMonth };
    const pnlByMonth = prepareMonthlyPnlChartData(baseData);
    const monthlyAveragePnl =
      pnlByMonth.length > 0
        ? sumBy(pnlByMonth, "barValue") / pnlByMonth.length
        : 0;
    const bestPnl =
      pnlByMonth.length > 0
        ? (maxBy(pnlByMonth, "barValue")?.barValue ?? 0)
        : 0;
    const worstPnl =
      pnlByMonth.length > 0
        ? (minBy(pnlByMonth, "barValue")?.barValue ?? 0)
        : 0;
    const cumulativePnl = netPnlForDateRange(baseData);
    const equityPositionPercentages =
      calculateEquityPositionsPercentages(positions);
    setPnlByMonth(pnlByMonth);
    setMonthlyAveragePnl(monthlyAveragePnl);
    setCumulativePnl(cumulativePnl);
    setBestPnl(bestPnl);
    setWorstPnl(worstPnl);
    setEquityPositionPercentages(equityPositionPercentages);
    setStartDate(startDate);
    setEndDate(endDate);
  }, [trades, positions, timePeriod]);

  return (
    <div className="bg-slate-100 dark:bg-gray-800 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <div className="p-8">
          <div className="flex justify-center">
            <div className="flex flex-col w-full lg:w-10/12">
              <div className="align-middle inline-block">
                <div className="flex flex-wrap justify-between mb-8">
                  {!isLoading ? (
                    <div className="w-full">
                      <div className="mb-12">
                        <TimePeriodSelector
                          startDate={startDate}
                          endDate={endDate}
                          allTimePeriods={TIME_PERIODS}
                          selectedTimePeriod={timePeriod}
                          onTimePeriodChange={setTimePeriod}
                        />
                        <PnlOverviewPanel
                          cumulativePnl={cumulativePnl}
                          bestPnl={bestPnl}
                          worstPnl={worstPnl}
                          monthlyAveragePnl={monthlyAveragePnl}
                        />
                        {!isEmpty(pnlByMonth) && (
                          <MonthlyPnLBarChart chartData={pnlByMonth} />
                        )}
                        {!isEmpty(equityPositionPercentages) && (
                          <PositionsOverviewPanel
                            positionPercentages={equityPositionPercentages}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="mx-auto pb-5">
                      <CircularProgress />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MetricsComponent;

const MonthlyPnLBarChart = ({
  chartData,
}: {
  chartData: MonthlyPnLChartData[];
}) => (
  <div className="mb-12 h-56 lg:h-128">
    <h4 className="mb-6 dark:text-gray-300 text-2xl font-semibold">
      Monthly P/L
    </h4>
    <CompositePnLChart
      data={chartData}
      margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
      tooltipDateFormatter={(d) => format(d, "MMM/yyyy")}
    />
  </div>
);

const PositionsOverviewPanel = ({
  positionPercentages,
}: {
  positionPercentages: EquityPositionsPieChartData[];
}) => (
  <div className="mb-10">
    <h2 className="font-semibold text-2xl text-slate-700 text-gray-900 dark:text-gray-300 mb-6">
      Current positions overview
    </h2>
    <div className="flex items-center justify-around">
      <PositionsPieChart data={positionPercentages} />
    </div>
  </div>
);

const PnlOverviewPanel = ({
  cumulativePnl,
  bestPnl,
  worstPnl,
  monthlyAveragePnl,
}: {
  cumulativePnl: number;
  bestPnl: number;
  worstPnl: number;
  monthlyAveragePnl: number;
}) => (
  <div className="mb-10">
    <h2 className="font-semibold text-2xl text-slate-700 text-gray-900 dark:text-gray-300 mb-6">
      P/L Overview
    </h2>
    <div className="flex items-center justify-between flex-wrap gap-x-2">
      <CardComponent title="Cumulative" amount={cumulativePnl} className="grow">
        <CurrencyDollarIcon className="h-12 w-12 dark:stroke-gray-300 stroke-gray-500" />
      </CardComponent>
      <CardComponent title="Best month" amount={bestPnl} className="grow">
        <ChevronDoubleUpIcon className="h-12 w-12 stroke-teal-800 dark:stroke-teal-500" />
      </CardComponent>
      <CardComponent title="Worst month" amount={worstPnl} className="grow">
        <ChevronDoubleDownIcon className="h-12 w-12 stroke-rose-800 dark:stroke-rose-500" />
      </CardComponent>
      <CardComponent
        title="Monthly average"
        amount={monthlyAveragePnl}
        className="grow"
      >
        <CurrencyDollarIcon className="h-12 w-12 dark:stroke-gray-300 stroke-gray-500" />
      </CardComponent>
    </div>
  </div>
);

const TimePeriodSelector = ({
  startDate,
  endDate,
  allTimePeriods,
  selectedTimePeriod,
  onTimePeriodChange,
}: {
  startDate: Date;
  endDate: Date;
  allTimePeriods: TimePeriod[];
  selectedTimePeriod: TimePeriod;
  onTimePeriodChange: (timePeriod: TimePeriod) => void;
}) => (
  <div className="mb-6">
    <h2 className="font-semibold text-2xl text-slate-700 text-gray-900 dark:text-gray-300 mb-6">
      Time Period
    </h2>
    <div className="flex mb-3">
      {allTimePeriods.map((timePeriod) =>
        timePeriod === selectedTimePeriod ? (
          <div
            className="px-3 py-1 dark:text-gray-100 text-gray-100 bg-teal-800 rounded"
            key={timePeriod}
          >
            {timePeriod}
          </div>
        ) : (
          <div
            className="px-3 py-1 dark:text-gray-100 cursor-pointer"
            key={timePeriod}
          >
            <span onClick={() => onTimePeriodChange(timePeriod)}>
              {timePeriod}
            </span>
          </div>
        ),
      )}
    </div>
    <p className="text-gray-900 dark:text-gray-300 text-sm">
      Data below comprises trades from
      <strong>&nbsp;{format(startDate, "MMMM do, yyyy")}&nbsp;</strong>
      to
      <strong>&nbsp;{format(endDate, "MMMM do, yyyy")}</strong>.
    </p>
  </div>
);

const determineStartDate = (
  selectedTimePeriod: TimePeriod,
  trades: Trade[],
): Date => {
  switch (selectedTimePeriod) {
    case "All-time":
      return !isEmpty(trades)
        ? startOfMonth(tradeStartDate(trades[0]))
        : new Date();
    case "1Y":
      return startOfMonth(addMonths(new Date(), -12));
    case "YTD":
      return startOfMonth(startOfYear(new Date()));
    default:
      throw new Error(`Invalid time period ${selectedTimePeriod}`);
  }
};

// Returns an array of MonthlyPnLChartData objects with Net and cumulative
// PNL for each month between startDate and endDate
const prepareMonthlyPnlChartData = ({
  tradesByMonth,
  startDate,
  endDate,
}: {
  // key is the month in the format yyyy-MM (eg 2022/01)
  tradesByMonth: Record<string, Trade[]>;
  startDate: Date;
  endDate: Date;
}): MonthlyPnLChartData[] => {
  const totalMonths = differenceInMonths(endDate, startDate);
  const months = [
    startDate,
    ...[...Array(totalMonths).keys()].map((d) => addMonths(startDate, d + 1)),
  ];
  let cumulativePnL = 0;
  return months
    .map((date) => {
      const formattedDate = format(date, "yyyy-MM");
      const tradesForMonth = tradesByMonth[formattedDate];
      const netPnL = sumBy(tradesForMonth, (t) => t.netProfit);
      cumulativePnL += netPnL;
      return { date, barValue: netPnL, lineValue: cumulativePnL };
    })
    .filter((d) => d.barValue !== 0);
};

// Returns the net profit or loss amount for a given date range, given a set of
// trades grouped by month
const netPnlForDateRange = ({
  tradesByMonth,
  startDate,
  endDate,
}: {
  // key is the month in the format yyyy-MM (eg 2022/01)
  tradesByMonth: Record<string, Trade[]>;
  startDate: Date;
  endDate: Date;
}): number => {
  const totalMonthsInPeriod = differenceInMonths(endDate, startDate);
  const monthsInPeriod = [
    startDate,
    ...[...Array(totalMonthsInPeriod).keys()].map((d) =>
      addMonths(startDate, d + 1),
    ),
  ];
  const netProfitByMonth = monthsInPeriod.map((date) => {
    const formattedDate = format(date, "yyyy-MM");
    const tradesForMonth = tradesByMonth[formattedDate];
    return sumBy(tradesForMonth, (t) => t.netProfit);
  });
  return sum(netProfitByMonth);
};

const calculateEquityPositionsPercentages = (
  positions: AccountPosition[],
): EquityPositionsPieChartData[] => {
  const equities = sortBy(
    filterEquities(positions),
    (p) => p.instrument.symbol,
  );
  const positionSizes = equities.map((e) => {
    return { name: e.instrument.symbol, value: positionSize(e) };
  });
  const total = sum(positionSizes.map((s) => s.value));
  return positionSizes.map((s) => {
    return { name: s.name, value: +((s.value / total) * 100).toFixed(1) };
  });
};
