import { useState } from "react";
import { reverse, sortBy } from "lodash";

/**
 * Returns a sorted array and a function that sorts the provided array by the provided column name
 * and updates its state on every call.
 */
export default function useSortable<T>(
  items: T[],
  initialSortPropertyName: keyof T,
  initialSortOrder: "asc" | "desc" = "asc",
  // eslint-disable-next-line no-unused-vars
): [T[], (_: keyof T) => void] {
  const [sortColumn, setSortColumn] = useState(initialSortPropertyName);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);
  const sortedItems = sortBy(items, (option) => option[sortColumn]);
  return [
    sortOrder === "desc" ? reverse(sortedItems) : sortedItems,
    (column: keyof T) => {
      setSortColumn(column);
      setSortOrder(
        sortColumn === column && sortOrder === "asc" ? "desc" : "asc",
      );
    },
  ];
}
