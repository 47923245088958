import { PositionType } from "./models/positionType";
import React from "react";

const LongShortPill = ({ type }: { type: PositionType }) =>
  type === PositionType.LONG ? (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
      Long
    </span>
  ) : (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-400">
      Short
    </span>
  );
export default LongShortPill;
