import React from "react";
import CurrencyAmount from "./currencyAmount";
import { PositionType } from "./models/positionType";
import { formatAsCurrency, formatAsPercentage } from "./util/Format";
import AnimatedPrice from "./AnimatedPrice";
import useSortable from "./util/useSortable";

export type EquityPosition = {
  symbol: string;
  type: PositionType;
  quantity: number;
  price: number;
  avgPrice: number;
  plUsd: number;
  plPercent: number;
};

// Renders a table of equity positions
const EquitiesTable = ({ equities }: { equities: EquityPosition[] }) => {
  const [sortedEquities, sortTableBy] = useSortable(equities, "symbol");
  return (
    <table className="w-full divide-y divide-gray-200 dark:divide-gray-800 rounded-lg overflow-hidden shadow-lg">
      <thead className="bg-gray-50 dark:bg-gray-700">
        <tr className="flex flex-col flex-nowrap table-row">
          <th
            onClick={() => sortTableBy("symbol")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-center"
          >
            <span className="cursor-pointer">symbol</span>
          </th>
          <th
            onClick={() => sortTableBy("quantity")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">qty</span>
          </th>
          <th
            onClick={() => sortTableBy("price")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">price</span>
          </th>
          <th
            onClick={() => sortTableBy("avgPrice")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">avg. price</span>
          </th>
          <th
            onClick={() => sortTableBy("plUsd")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">P/L $</span>
          </th>
          <th
            onClick={() => sortTableBy("plPercent")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">P/L %</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-300 dark:divide-gray-800 flex-1 sm:flex-none">
        {sortedEquities.map((equity) => (
          <tr
            key={equity.symbol}
            className="table-row flex flex-col flex-nowrap md:flex-none"
          >
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap dark:bg-gray-700 bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 hover:transition-all">
              <div className="flex justify-center">
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-center">
                  <a
                    href={`https://terminal.huskly.finance?s=${equity.symbol}`}
                  >
                    {equity.symbol}
                  </a>
                </div>
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                <span className="font-mono">{equity.quantity}</span>
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                <span className="font-mono">
                  <AnimatedPrice price={equity.price} />
                </span>
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                <span className="font-mono">
                  {formatAsCurrency(equity.avgPrice)}
                </span>
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                <CurrencyAmount amount={equity.plUsd} />
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                {formatAsPercentage(equity.plPercent)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EquitiesTable;
