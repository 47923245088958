import React, { RefObject } from "react";
import { isDarkMode } from "./util/Util";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";

const darkModeToggle: RefObject<SVGSVGElement> = React.createRef();
const lightModeToggle: RefObject<SVGSVGElement> = React.createRef();

const ThemeSelector = () => {
  const [mode, setMode] = React.useState(isDarkMode() ? "dark" : "light");
  const setTheme = (theme: string) => {
    localStorage.theme = theme;
    if (isDarkMode()) {
      document.documentElement.classList.add("dark");
      document.documentElement.style.colorScheme = "dark";
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.style.colorScheme = "";
    }
    setMode(theme);
  };
  return (
    <div className="flex items-center themeselector-wrapper">
      <div className="cursor-pointer dark:stroke-slate-900 fill-slate-900 stroke-slate-900">
        {mode === "dark" ? (
          <MoonIcon
            className="w-5 h-5 stroke-current fill-current"
            ref={darkModeToggle}
            onClick={() => setTheme("light")}
          />
        ) : (
          <SunIcon
            className="w-5 h-5 stroke-current fill-current"
            ref={lightModeToggle}
            onClick={() => setTheme("dark")}
          />
        )}
      </div>
    </div>
  );
};

export default ThemeSelector;
