import * as React from "react";

const HusklyLogo = ({
  style,
  className,
}: {
  style?: any;
  className?: string;
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 358.1 425.7"
    style={style}
    className={className}
  >
    <polyline points="132.8,210.9 109.4,200.3 129.1,186 151,210.9 " />
    <path
      d="M308,135.1L289.4,0L179,75.2L68.6,0L50.1,135.1L0,231l50.4,120.9L61,331.8l70.7,93.9v-66.5l47.3,46l47.3-46
                              v66.5l70.7-93.9l10.6,20.1L358,231L308,135.1z M275,35.7l9.8,94.8l-20-42.1l-21.3,21.4l1.7-26.7l-35.5,9.8L275,35.7z M83,35.7
                              l65.4,57.2l-35.5-9.8l1.7,26.7L93.3,88.4l-20,42.1L83,35.7z M182.1,176.5l56.7-58.3l98,110.1L179,379.1L21.2,228.2l101-110.1"
    />
    <polyline points="166.2,302.2 150.6,291.3 165.7,307.4 182.8,307.3 199.8,307.4 214.9,291.3 199.2,302.2 " />
    <path d="M159.7,267.6l22.4,27.7l23.7-29.3" />
    <path d="M229.9,210.8c1.3-0.7,23.3-10.6,23.3-10.6L233.5,186l-23.9,24.9" />
    <polyline points="226.7,201.9 240.2,198.4 233.8,193.2 " />
    <polyline points="135.7,201.9 122.2,198.4 128.6,193.2 " />
  </svg>
);
export default HusklyLogo;
