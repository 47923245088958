import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardComponent from "./dashboardComponent";
import MetricsComponent from "./metricsComponent";
import TransactionsComponent from "./transactionsComponent";
import LandingPage from "./LandingPage";
import TradeDetailsComponent from "./TradeDetailsComponent";

const AppRoutes = (props: any) => {
  const routes: { path: string; component: any }[] = [
    { path: "/trades/*", component: TradeDetailsComponent },
    { path: "/dashboard/*", component: DashboardComponent },
    { path: "/trades", component: TransactionsComponent },
    { path: "/metrics", component: MetricsComponent },
  ];
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((r: { path: string; component: any }) => {
          const path = r.path;
          const Comp = r.component;
          return <Route path={path} key={path} element={<Comp {...props} />} />;
        })}
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
