import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

const SyncTradesPrompt = () => {
  const [loading, setLoading] = useState(false);
  const onClickSync = (e: any) => {
    if (!loading) {
      setLoading(true);
    } else {
      e.preventDefault();
    }
  };
  return (
    <div className="bg-slate-100 dark:bg-gray-800 min-h-screen pb-8">
      <div className="max-w-xl mx-auto">
        <div className="p-8">
          <div className="flex justify-center">
            <div className="flex flex-col max-w-full">
              <div className="align-middle inliner-block max-w-full overflow-auto">
                <div className="flex flex-wrap justify-center mb-8">
                  <h3 className="font-semibold mb-4 text-lg text-center">
                    No trades were found. Please click below to sync your
                    transaction history with TD Ameritrade.
                  </h3>
                  <a
                    href="/transactions/sync"
                    onClick={onClickSync}
                    className={`mb-8 group flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none ${
                      loading
                        ? "bg-gray-900 text-gray-700 border-black"
                        : "bg-teal-700 hover:bg-teal-800 text-white focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    }`}
                  >
                    <ArrowPathIcon
                      className={`h-5 mr-2 ${
                        loading
                          ? "animate-spin text-gray-700"
                          : "text-teal-500 group-hover:text-teal-400"
                      }`}
                    />
                    {loading ? "Syncing transactions..." : "Sync transactions"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SyncTradesPrompt;
