import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatAsCurrency, formatAsCurrencyAbbreviated } from "./util/Format";
import CustomAmountTooltip from "./customAmountTooltip";
import { format } from "date-fns";
import * as React from "react";
import { ChartMargins } from "./models/chartMargins";

const dateFormatter = (date: Date) => {
  return date.getMonth() === 0 ? format(date, "yy") : format(date, "MMM");
};

export type CompositePnLChartProps = {
  width?: number;
  height?: number;
  margin?: ChartMargins;
  tooltipDateFormatter?: (_: Date) => string;
  data: { date: Date; barValue: number; lineValue: number }[];
};

/** A composite chart that displays both a bar chart and a line chart. */
const CompositePnLChart = ({
  width = 1000,
  height = 600,
  margin,
  data,
  tooltipDateFormatter,
}: CompositePnLChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart width={width} height={height} data={data} margin={margin}>
        <CartesianGrid strokeDasharray="3 3" stroke="currentColor" />
        <XAxis dataKey="date" tickFormatter={dateFormatter} />
        <YAxis tickFormatter={formatAsCurrencyAbbreviated} />
        <Tooltip
          content={
            <CustomAmountTooltip
              dateFormatter={tooltipDateFormatter}
              valueFormatter={(d) => (
                <p>
                  {"Net P/L: "}
                  <em>{formatAsCurrency(d.barValue)}</em>
                  <br />
                  {"Cumulative P/L: "}
                  <em>{formatAsCurrency(d.lineValue)}</em>
                </p>
              )}
            />
          }
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="lineValue"
          stroke="#8884d8"
          dot={false}
          strokeWidth={2}
          name="Cumulative P/L"
        />
        <Bar dataKey="barValue" fill="#8884d8" name="Net P/L">
          {data.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={data[index].barValue > 0 ? "#82ca9d" : "#f65b5b"}
            />
          ))}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};
export default CompositePnLChart;
