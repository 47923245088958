import { formatDistance } from "date-fns";
import React from "react";
import CurrencyAmount from "./currencyAmount";
import { PositionType } from "./models/positionType";
import { formatAsCurrency, formatAsPercentage } from "./util/Format";
import AnimatedPrice from "./AnimatedPrice";
import useSortable from "./util/useSortable";

export type OptionPosition = {
  terminalSymbol: string;
  symbol: string;
  type: PositionType;
  quantity: number;
  price: number;
  avgPrice: number;
  plUsd: number;
  plPercent: number;
  expiration: Date;
  isITM: boolean; // Is the option in the money?
};

// Renders a table of option positions, optionally sorted by column header
const OptionsTable = ({ options }: { options: OptionPosition[] }) => {
  const [sortedOptions, sortTableBy] = useSortable(options, "expiration");
  return (
    <table className="w-full divide-y divide-gray-200 dark:divide-gray-800 rounded-lg shadow-lg overflow-hidden">
      <thead className="bg-gray-50 dark:bg-gray-700">
        <tr className="flex flex-col flex-no wrap table-row">
          <th
            onClick={() => sortTableBy("symbol")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-center"
          >
            <span className="cursor-pointer">symbol</span>
          </th>
          <th
            onClick={() => sortTableBy("expiration")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">Time to exp.</span>
          </th>
          <th
            onClick={() => sortTableBy("quantity")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">qty</span>
          </th>
          <th
            onClick={() => sortTableBy("price")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">price</span>
          </th>
          <th
            onClick={() => sortTableBy("avgPrice")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">avg. price</span>
          </th>
          <th
            onClick={() => sortTableBy("plUsd")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">P/L $</span>
          </th>
          <th
            onClick={() => sortTableBy("plPercent")}
            scope="col"
            className="p-3 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider hover:text-gray-900 dark:hover:text-gray-100 text-right"
          >
            <span className="cursor-pointer">P/L %</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-300 dark:divide-gray-800 flex-1 sm:flex-none">
        {sortedOptions.map((option) => (
          <tr
            key={option.symbol}
            className="flex flex-col flex-nowrap table-row"
          >
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap dark:bg-gray-700 bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 hover:transition-all">
              <div className="flex justify-center">
                <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-center">
                  <a
                    href={`https://terminal.huskly.finance?s=${option.terminalSymbol}`}
                  >
                    {option.symbol}
                  </a>
                  {option.isITM && (
                    <span className="ml-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-700 text-gray-200 dark:bg-orange-900 dark:text-gray-100">
                      ITM
                    </span>
                  )}
                </div>
              </div>
            </td>
            <td className="px-2 py-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                {formatDistance(option.expiration, new Date())}
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                <span className="font-mono">{option.quantity}</span>
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                <span className="font-mono">
                  <AnimatedPrice price={option.price} />
                </span>
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                <span className="font-mono">
                  {formatAsCurrency(option.avgPrice)}
                </span>
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                <CurrencyAmount amount={option.plUsd} />
              </div>
            </td>
            <td className="p-3 md:px-6 md:py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100 text-right">
                {formatAsPercentage(option.plPercent)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OptionsTable;
