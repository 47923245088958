import KeyIcon from "@heroicons/react/24/outline/KeyIcon";
import React, { useState } from "react";
import {
  AdjustmentsHorizontalIcon,
  WalletIcon,
  BellAlertIcon,
  ChartBarSquareIcon,
  DocumentTextIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";

/** Dropdown menu for logged in users */
const AppDropdownMenu = ({
  formAuthenticityToken,
  selectedItem,
}: {
  formAuthenticityToken: string;
  selectedItem:
    | "dashboard"
    | "transactions"
    | "metrics"
    | "alerts"
    | "settings";
}) => {
  const selectedKlass = "bg-primary-800";
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  return (
    <>
      <div
        className={`ml-4 p-4 rounded-t ${
          dropdownVisible && "dark:bg-gray-900 bg-gray-200"
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={toggleDropdown}
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>
      <nav
        className={`absolute dark:bg-gray-900 bg-gray-200 rounded top-16 right-4 drop-shadow z-10 ${
          !dropdownVisible && "hidden"
        }`}
      >
        <div
          className={
            `px-4 py-3 dark:hover:bg-gray-800 hover:bg-gray-100 md:hidden ` +
            (selectedItem === "dashboard" ? selectedKlass : "")
          }
        >
          <HomeIcon className="h-4 mr-3 inline-block" />
          <a href="/">Dashboard</a>
        </div>
        <div
          className={
            `px-4 py-3 dark:hover:bg-gray-800 hover:bg-gray-100 md:hidden ` +
            (selectedItem === "transactions" ? selectedKlass : "")
          }
        >
          <DocumentTextIcon className="h-4 mr-3 inline-block" />
          <a href="/trades">Transactions</a>
        </div>
        <div
          className={
            `px-4 py-3 dark:hover:bg-gray-800 hover:bg-gray-100 md:hidden ` +
            (selectedItem === "metrics" ? selectedKlass : "")
          }
        >
          <ChartBarSquareIcon className="h-4 mr-3 inline-block" />
          <a href="/metrics">Metrics</a>
        </div>
        <div
          className={
            `px-4 py-3 dark:hover:bg-gray-800 hover:bg-gray-100 md:hidden ` +
            (selectedItem === "alerts" ? selectedKlass : "")
          }
        >
          <a href="/alerts">
            <BellAlertIcon className="h-4 mr-3 inline-block" />
            Alerts
          </a>
        </div>
        <div
          className={
            `px-4 py-3 rounded dark:hover:bg-gray-800 hover:bg-gray-100 ` +
            (selectedItem === "settings" ? selectedKlass : "")
          }
        >
          <a href="/users/edit">
            <AdjustmentsHorizontalIcon className="h-4 mr-3 inline-block" />
            Account details
          </a>
        </div>
        <div className="px-4 py-3 rounded dark:hover:bg-gray-800 hover:bg-gray-100">
          <form action="/customer_portal_sessions" method="post">
            <input
              type="hidden"
              name="authenticity_token"
              value={formAuthenticityToken}
            />
            <WalletIcon className="h-4 mr-3 inline-block" />
            <input
              type="submit"
              value="Manage billing"
              className="cursor-pointer"
            />
          </form>
        </div>
        <div className="px-4 py-3 rounded dark:hover:bg-gray-800 hover:bg-gray-100">
          <a href="/users/sign_out">
            <KeyIcon className="h-4 w-4 mr-3 inline-block" />
            Sign out
          </a>
        </div>
      </nav>
    </>
  );
};
export default AppDropdownMenu;
