import React from "react";
import { formatAsCurrency } from "./util/Format";

// Displays a color coded currency amount depending on the value being positive or negative.
const CurrencyAmount = ({
  amount,
  extraClass,
}: {
  amount: number;
  extraClass?: string;
}) => {
  const klass =
    amount > 0
      ? "text-teal-800 dark:text-teal-500"
      : "text-rose-800 dark:text-rose-500";
  return (
    <span className={`${klass} ${extraClass} font-mono`}>
      {formatAsCurrency(amount)}
    </span>
  );
};
export default CurrencyAmount;
