import {
  addDays,
  differenceInDays,
  format,
  isValid,
  parse,
  startOfMonth,
} from "date-fns";
import { every, groupBy, isEmpty, sortBy, sum, sumBy } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import HusklyAccountsApiClient from "./api/husklyAccountsApiClient";
import CurrencyAmount from "./currencyAmount";
import { Trade, tradeDate } from "./models/Trade";
import { formatAsPercentage } from "./util/Format";
import TradeHistoryTable from "./tradeHistoryTable";
import CircularProgress from "./circularProgress";
import { useSearchParams } from "react-router-dom";
import PnLBarChart from "./pnLBarChart";

const DATE_FORMAT = "yyyy-MM-dd";

const TransactionsComponent = () => {
  const accountDetailsFetcher = new HusklyAccountsApiClient();
  const [isLoading, setIsLoading] = useState(false);
  const [trades, setTrades] = useState<Trade[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryStartDate = searchParams.get("startDate");
  const queryEndDate = searchParams.get("endDate");
  const querySymbol = searchParams.get("symbol");
  const queryAccountId = searchParams.get("tdaAccountId");
  const now = new Date();
  const [startDate, setStartDate] = useState<Date | undefined>(
    queryStartDate
      ? parse(queryStartDate, DATE_FORMAT, now)
      : startOfMonth(now),
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    queryEndDate ? parse(queryEndDate, DATE_FORMAT, now) : now,
  );
  // These needs to be set to empty string by default due to how react controlled
  // components work: https://reactjs.org/docs/forms.html#controlled-components
  const [symbol, setSymbol] = useState(querySymbol ?? "");
  const [tdaAccountId, setTdaAccountId] = useState(queryAccountId ?? "");

  const requestTrades = async () => {
    if (!endDate || !endDate || startDate!! > endDate) {
      setTrades([]);
      setIsLoading(false);
      return;
    }
    const response = await accountDetailsFetcher.requestTrades({
      startDate: startDate!!,
      endDate,
      tdaAccountId,
      symbol,
    });
    if ("error" in response) {
      console.error("API request failed: " + response.error);
      setTrades([]);
      setIsLoading(false);
    } else {
      const sortedTrades = sortBy(response, tradeDate);
      setTrades(sortedTrades);
      setIsLoading(false);
    }
  };

  const onChangePeriodRaw = (event: any) => {
    const value = event.target.value;
    if (value) {
      const dates = value
        .split(" - ")
        .map((d: string) => parse(d, "MM/dd/yyyy", new Date()));
      if (every(dates, (d) => isValid(d))) {
        onChangePeriod(dates);
      }
    }
  };

  useEffect(() => {
    // update query string (search params) to reflect
    const newSearchParams: Record<string, string> = {};
    if (startDate) newSearchParams.startDate = format(startDate, DATE_FORMAT);
    if (endDate) newSearchParams.endDate = format(endDate, DATE_FORMAT);
    if (symbol) newSearchParams.symbol = symbol;
    if (tdaAccountId) newSearchParams.tdaAccountId = tdaAccountId;
    setSearchParams(newSearchParams);
  }, [startDate, endDate, symbol, tdaAccountId]);

  const onChangePeriod = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;
    setStartDate(startDate ?? undefined);
    setEndDate(endDate ?? undefined);
    setIsLoading(true);
  };

  useEffect(() => {
    requestTrades().catch(console.error);
  }, [startDate, endDate, tdaAccountId, symbol]);

  const prepareChartData = (): { date: Date; value: number }[] => {
    if (!startDate || !endDate || startDate >= endDate) {
      return [];
    }
    const topLevelTrades = trades.filter(({ isInner }) => !isInner);
    const data = topLevelTrades.map((t) => ({
      date: parse(t.endDate, "yyyy-MM-dd", new Date()),
      value: t.netProfit,
    }));
    const totalDays = differenceInDays(endDate, startDate);
    const extraDays = [...Array(totalDays).keys()].map((d) =>
      addDays(startDate, d + 1),
    );
    const tradesByDate = groupBy(data, (d) => d.date);
    return [startDate, ...extraDays].map((date) => {
      // group trades by date then map by summing amounts for each given date
      return {
        date: date,
        value: sumBy(tradesByDate["" + date], (t) => t.value),
      };
    });
  };

  // Do not display inner trades in the history table (they are displayed in the trade details page instead)
  const topLevelTrades = trades.filter(({ isInner }) => !isInner);
  const periodNetPnL = sumBy(topLevelTrades, (t: Trade) => t.netProfit);
  const totalFees = sumBy(topLevelTrades, (t: Trade) => t.totalFees);
  const successRate =
    topLevelTrades.filter((t) => t.netProfit > 0).length /
    topLevelTrades.length;
  const weightedAveragePercentReturn =
    sum(topLevelTrades.map((t) => t.percentReturn * t.positionSize)) /
    sum(topLevelTrades.map((t) => t.positionSize));
  const averagePositionSize =
    sum(topLevelTrades.map((t) => t.positionSize)) / topLevelTrades.length;
  const hasDates = startDate && endDate && startDate < endDate;
  const chartData = prepareChartData();
  return (
    <div className="bg-slate-100 dark:bg-gray-800 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <div className="p-8">
          <div className="flex justify-center">
            <div className="flex flex-col w-full lg:w-10/12">
              <div className="align-middle inline-block min-w-full">
                <div className="flex flex-wrap sm:space-x-4 mb-12 w-full">
                  <TransactionPeriodSelector
                    startDate={startDate}
                    endDate={endDate}
                    onChangePeriod={onChangePeriod}
                    onChangePeriodRaw={onChangePeriodRaw}
                  />
                  <TransactionResultsFilter
                    tdaAccountId={tdaAccountId}
                    symbol={symbol}
                    onChangeTdaAccountId={(accountId) =>
                      setTdaAccountId(accountId ?? "")
                    }
                    onChangeSymbol={(symbol) => setSymbol(symbol ?? "")}
                  />
                </div>
                {!endDate ? (
                  <></>
                ) : !isLoading ? (
                  <>
                    {!isEmpty(topLevelTrades) && (
                      <div className="mb-8">
                        <h3 className="font-semibold text-3xl text-gray-900 dark:text-gray-300 mb-6">
                          Statistics
                        </h3>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 lg:gap-x-8">
                          <div className="dark:text-gray-300 font-semibold">
                            Total trades
                          </div>
                          <div className="text-right dark:text-gray-300 font-mono md:mr-8">
                            {trades.length}
                          </div>
                          <div className="dark:text-gray-300 font-semibold">
                            Net P/L
                          </div>
                          <div className="text-right md:mr-8">
                            <CurrencyAmount amount={periodNetPnL} />
                          </div>
                          <div className="dark:text-gray-300 font-semibold">
                            Total paid in fees
                          </div>
                          <div className="text-right md:mr-8">
                            <CurrencyAmount amount={-totalFees} />
                          </div>
                          <div className="dark:text-gray-300 font-semibold">
                            Success rate
                          </div>
                          <div className="text-gray-900 dark:text-gray-100 text-right font-mono md:mr-8">
                            {formatAsPercentage(successRate)}
                          </div>
                          <div className="dark:text-gray-300 font-semibold">
                            Avg. return
                          </div>
                          <div className="text-right dark:text-gray-300 font-mono md:mr-8">
                            {formatAsPercentage(weightedAveragePercentReturn)}
                          </div>
                          <div className="dark:text-gray-300 font-semibold">
                            Avg. position size
                          </div>
                          <div className="text-right md:mr-8">
                            <CurrencyAmount amount={averagePositionSize} />
                          </div>
                        </div>
                      </div>
                    )}
                    {hasDates && !isEmpty(topLevelTrades) && (
                      <div className="mb-12 h-56 lg:h-128">
                        <PnLBarChart
                          data={chartData}
                          height={400}
                          tooltipDateFormatter={(d) => format(d, "MM/dd/yyyy")}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 40,
                            bottom: 5,
                          }}
                          dateFormatter={(d) => format(d, "MM/dd/yyyy")}
                        />
                      </div>
                    )}
                    <div className="pb-8">
                      <div className="overflow-auto">
                        {!isEmpty(topLevelTrades) ? (
                          <>
                            <h3 className="font-semibold text-3xl text-gray-900 dark:text-gray-300 mb-6">
                              Transaction History
                            </h3>
                            <TradeHistoryTable trades={topLevelTrades} />
                          </>
                        ) : (
                          <p className="text-center dark:text-gray-300">
                            No transactions found for this period.
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="text-center pb-5">
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Start and end date selector
const TransactionPeriodSelector = ({
  startDate,
  endDate,
  onChangePeriod,
  onChangePeriodRaw,
}: {
  startDate: Date | undefined;
  endDate: Date | undefined;
  onChangePeriod: (dates: [Date | null, Date | null]) => void;
  onChangePeriodRaw: (event: any) => void;
}) => (
  <div className="flex flex-col flex-wrap grow">
    <h2 className="font-semibold text-3xl text-gray-900 dark:text-gray-300 mb-6">
      Time period
    </h2>
    <div className="rounded-lg shadow dark:bg-gray-700 bg-white mb-3">
      <div className="flex justify-center p-8">
        <div className="p-4 w-64">
          <span className="font-medium dark:text-gray-300 text-gray-500">
            Start & End date
          </span>
          <div className="mt-3">
            <DatePicker
              selected={startDate}
              onChange={onChangePeriod}
              onChangeRaw={onChangePeriodRaw}
              startDate={startDate}
              endDate={endDate}
              selectsRange={true}
              isClearable={true}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

// Allow filtering transactions by account and symbol
const TransactionResultsFilter = ({
  tdaAccountId,
  symbol,
  onChangeTdaAccountId,
  onChangeSymbol,
}: {
  tdaAccountId?: string;
  symbol?: string;
  onChangeTdaAccountId: (tdaAccountId: string) => void;
  onChangeSymbol: (symbol: string) => void;
}) => {
  return (
    <div className="flex flex-col grow">
      <h2 className="font-semibold text-3xl text-gray-900 dark:text-gray-300 mb-6">
        Filters
      </h2>
      <div className="rounded-lg shadow dark:bg-gray-700 bg-white mb-3 grow-0">
        <div className="flex flex-wrap justify-center p-8">
          <div className="p-4 w-64">
            <span className="font-medium dark:text-gray-300 text-gray-500">
              Account number
            </span>
            <div className="mt-3">
              <input
                type="text"
                placeholder="e.g.: 123456"
                value={tdaAccountId}
                onChange={(e) => onChangeTdaAccountId(e.target.value)}
                className="dark:bg-zinc-700 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 dark:border-gray-500 placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-gray-200 focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>
          <div className="p-4 w-64">
            <span className="font-medium dark:text-gray-300 text-gray-500">
              Symbol
            </span>
            <div className="mt-3">
              <input
                type="text"
                placeholder="e.g. AAPL"
                value={symbol}
                onChange={(e) => onChangeSymbol(e.target.value)}
                className="dark:bg-zinc-700 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 dark:border-gray-500 placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-gray-200 focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TransactionsComponent;
