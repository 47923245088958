import { format } from "date-fns";
import { formatAsCurrency } from "./util/Format";
import * as React from "react";
import { ReactNode } from "react";

const tooltipStyle = {
  padding: 6,
  backgroundColor: "#fafafa",
  color: "#333",
  border: "1px solid #ccc",
};

export type CustomDateAmountTooltipProps = {
  active?: boolean;
  payload?: any;
  dateFormatter?: (_: Date) => string;
  valueFormatter?: (_: any) => ReactNode;
};

/** Custom Tooltip for `PnLBarChart` */
const CustomAmountTooltip = ({
  active = false,
  payload,
  dateFormatter = (d) => format(d, "MMM yyyy"),
  valueFormatter = (d) => formatAsCurrency(d.value),
}: CustomDateAmountTooltipProps) => {
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <div className="area-chart-tooltip" style={tooltipStyle}>
        <p>{currData ? dateFormatter(currData.date) : " -- "}</p>
        <>{currData && valueFormatter(currData)}</>
      </div>
    );
  }

  return null;
};

export default CustomAmountTooltip;
