import LongShortPill from "./longShortPill";
import { PositionType } from "./models/positionType";
import { differenceInDays, format, parse } from "date-fns";
import CurrencyAmount from "./currencyAmount";
import { formatAsPercentage } from "./util/Format";
import { humanSymbol, longOutcome, Trade } from "./models/Trade";
import React from "react";
import { numberToCssClass } from "./util/Util";

const TradeDetailsGrid = ({ trade }: { trade: Trade }) => {
  const {
    startDate,
    endDate,
    netProfit,
    percentReturn,
    totalFees,
    positionSize,
    quantity,
    averageOpeningPrice,
    averageClosingPrice,
    tdaAccountId,
  } = trade;
  const symbol = humanSymbol(trade);
  const startDateObj = parse(startDate, "yyyy-MM-dd", new Date());
  const endDateObj = parse(endDate, "yyyy-MM-dd", new Date());
  const formattedStartDate = format(startDateObj, "MM/dd/yyyy");
  const formattedEndDate = format(endDateObj, "MM/dd/yyyy");
  const klass = numberToCssClass(netProfit);
  return (
    <div className="flex justify-center pb-10">
      <div className="flex flex-col w-full">
        <div className="align-middle inline-block min-w-full">
          {!trade.isOptionRoll && (
            <div className="mb-6">
              <h2 className="text-2xl">{symbol}</h2>
            </div>
          )}
          <div className="grid grid-cols-2 gap-2 lg:gap-x-8">
            <div>Type</div>
            <div className="text-right">
              <LongShortPill type={PositionType[trade.tradeType]} />
            </div>
            <div>Start date</div>
            <div className="text-right">{formattedStartDate}</div>
            <div>End date</div>
            <div className="text-right">{formattedEndDate}</div>
            <div>Quantity</div>
            <div className="text-right">{quantity}</div>
            <div>Duration</div>
            <div className="text-right">
              {differenceInDays(endDateObj, startDateObj)} days
            </div>
            <div>Position size</div>
            <div className="text-right">
              <CurrencyAmount amount={positionSize} />
            </div>
            <div>Opening price</div>
            <div className="text-right">
              <CurrencyAmount amount={averageOpeningPrice} />
            </div>
            <div>Closing price</div>
            <div className="text-right">
              <CurrencyAmount amount={averageClosingPrice} />
            </div>
            <div>Net profit/loss</div>
            <div className={`col-sm-9 text-right ${klass}`}>
              <CurrencyAmount amount={netProfit} />
            </div>
            <div>Total fees paid</div>
            <div className="col-sm-9 text-right">
              <CurrencyAmount amount={-totalFees} />
            </div>
            <div>Percent return</div>
            <div className="text-right">
              {formatAsPercentage(percentReturn)}
            </div>
            <div>Outcome</div>
            <div className="text-right">{longOutcome(trade)}</div>
            <div>Account number</div>
            <div className="text-right">#{tdaAccountId}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TradeDetailsGrid;
